import { fullDisplayDateInMilitaryTime } from "../../../common/utils";
import { BaseComponent } from "../../../common/component/base.component";
import { INotificationModel } from "../model/notification.model";
import { markRead, quickView, loadAll } from "../store/notification.slice";
import { connect } from "react-redux";
import { IGetAllNotificationsRequest } from "../model/request/get-all-notifications.request.model";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";

interface IProps {
  item: INotificationModel,
  markRead: (id: number) => void,
  quickView: () => void,
  loadAll: (request: IGetAllNotificationsRequest) => void,
}
class NotificationComponent extends BaseComponent<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <li className="dropdown-item notification-card">
        <Link to={ScreenUrls.Dashboard.Notifications()} className="p-0 inherit-styling">
          <div className={`notification-img ${this.props.item.isUnread ? "is-unread" : ""}`}>
            <i className={`icon feather icon-mail mr-0`}></i>
          </div>
          <div className="flex-1 ml-3">
            <div className="font-13">{this.props.item?.notificationLine}</div>
            <small>{this.props.item.notificationLine2}</small><br />
            <div className="d-flex mt-1 justify-content-between align-items-center">
              <small>{fullDisplayDateInMilitaryTime(this.props.item?.lastUpdatedOnUtc)}</small>
              <div className="d-flex">
                {

                  this.props.item?.pageUri?.length &&
                  <Link
                    className="py-1 mr-2 px-2 font-10 br-2 font-weight-normal btn-primary"
                    to={this.props.item?.pageUri}
                  >Open</Link>
                }

                {
                  this.props.item.isUnread &&
                  <button
                    className="border-0 py-1 px-2 f-10 br-2 btn-primary"
                    onClick={async (e) => {
                      e.preventDefault();
                      await this.props.markRead(this.props.item.id);
                      await this.props.quickView();
                      if (window.location.href?.endsWith("notifications")) {
                        await this.props.loadAll({ page: 1, pageSize: 10 });
                      }
                    }}
                  >Mark read</button>
                }

              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  }
}

export default connect(null, { markRead, quickView, loadAll })(NotificationComponent);

