import React from "react";
import { IWorkflowModel } from "../../model/workflow.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import {
    completeWorkflow,
    editWorkflow,
    loadWorkflowById,
    updateWorkflowDataState
} from "../../store/workflow.slice";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { ValidationScreens } from "../../../../common/enums";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

import ButtonGroupComponent from "../../../../common/component/button-group.component";
interface IProps {
    workflowData: IWorkflowModel,
    loadWorkflowById: (id: number) => void,
    editWorkflow: (data: IWorkflowModel) => void;
    completeWorkflow: (data: IWorkflowModel) => void,
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowDataState: (payload: IStoreSaveModel) => void;
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class ClosureWorkflowComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.WorkflowClosureStage);
    }

    render() {
        return <React.Fragment>
            {/* <article className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className={`form-group ${this.vResult("workflowCompletionNotes").className}`}>
                                <label htmlFor="workflowCompletionNotes">Workflow Completion Note</label>
                                <textarea id="workflowCompletionNotes" className="form-control" rows={4}
                                    value={this.props.workflowData.workflowCompletionNotes?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("workflowCompletionNotes", e.target.value);
                                        this.props.updateWorkflowDataState({ name: "workflowCompletionNotes", value: e.target.value })
                                    }} />
                                <ValidationMessageControl message={this.vResult("workflowCompletionNotes").message} />
                            </div>
                        </div>
                    </div>
                </div>
            </article> */}
            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.workflowData.isComplete || this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowData);
                            this.reloadForm();
                            if (error) return;

                            this.props.completeWorkflow(this.props.workflowData);
                            this.props.onChange();
                        }}>Complete</button>
                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.workflowData.isComplete || this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });
                        }}>Reject</button>
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.workflowData.isComplete || this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflow(this.props.workflowData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>

            {(() => { if (this.props.stageMoveLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowData: state.workflow.workflowData,
    stageMoveLoading: state.workflow.stageMoveLoading,
})
export default connect(mapStateToProps, { openStageAssignmentModal, loadWorkflowById, completeWorkflow, editWorkflow, updateWorkflowDataState })(ClosureWorkflowComponent);
