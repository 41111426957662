
import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import { IMyWorkModel } from "../model/my-work.model";
import { IWorkflowTaskFilter } from "../screen/my-work.page";

export default class MyWorkService {
    static async Load(request?: IWorkflowTaskFilter): Promise<IMyWorkModel> {
        return await HttpService.Get<IMyWorkModel>(ApiUrls.MyWork.Get(request));
    }
}
