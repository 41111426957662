import { BaseComponent } from "../../../common/component/base.component";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import { displayDate } from "../../../common/utils";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { IMyWorkModel } from "../model/my-work.model";
import { loadMyWork } from "../store/my-work.slice";
import { PoType, WorkflowTaskPriority } from "../../../common/enums";
import SearchBox from "../../../common/component/searchbox.component";

export interface IProps {
    accessLevel: number,
    myWorkData: IMyWorkModel,
    dataLoading: boolean,
    loadMyWork: (filters: IWorkflowTaskFilter) => void,
}

export interface IWorkflowTaskFilter {
    clientName?: string,
    poNumber?: string,
    reportNumber?: string,
}

class MyWorkPage extends BaseComponent<IProps, IWorkflowTaskFilter> {
    constructor(props) {
        super(props);
        this.state = {
            clientName: "",
            poNumber: "",
        }
    }
    componentDidMount() {
        this.props.loadMyWork({ clientName: "", poNumber: "" });
    }

    updateHandler(e, field: string) {
        if (e != null) {
            this.setState((prev) => {
                return { ...prev, [field]: e.target.value }
            });
            let filters = { clientName: field == "clientName" ? e.target.value : this.state.clientName, poNumber: field == "poNumber" ? e.target.value : this.state.poNumber, reportNumber: field == "reportNumber" ? e.target.value : this.state.reportNumber };
            this.props.loadMyWork(filters);
        }
    }

    render() {
        return <div className="pcoded-content">
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        {(() => { if (this.props.dataLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Workflows assigned to me</h5>
                                    </div>
                                    <div className="card-block ag-theme-alpine large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    headerName: "PO#", field: "purchaseOrder", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.poNumber == valueB?.poNumber) return 0;
                                                        return (valueA?.poNumber > valueB?.poNumber) ? 1 : -1;
                                                    },
                                                    suppressMovable: true,
                                                    cellRenderer: (params) => <span>{params.value?.poNumber}</span>
                                                },
                                                {
                                                    headerName: "Date", field: "purchaseOrder", width: 150, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.poDate == valueB?.poDate) return 0;
                                                        return (valueA?.poDate > valueB?.poDate) ? 1 : -1;
                                                    },
                                                    suppressMovable: true,
                                                    cellRenderer: (params) => <span>{displayDate(params.value?.poDate)}</span>
                                                },
                                                {
                                                    headerName: "Client", field: "purchaseOrder", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.client?.companyName == valueB?.client?.companyName) return 0;
                                                        return (valueA?.client?.companyName > valueB?.client?.companyName) ? 1 : -1;
                                                    },
                                                    suppressMovable: true,
                                                    cellRenderer: (params) => <span>{params.value?.client?.companyName}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Client Coordinator", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.purchaseOrder?.clientCoordinatorName == valueB?.purchaseOrder?.clientCoordinatorName) return 0;
                                                        return (valueA?.purchaseOrder?.clientCoordinatorName > valueB?.purchaseOrder?.clientCoordinatorName) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.data?.purchaseOrder?.clientCoordinatorName}</span>
                                                },
                                                {
                                                    headerName: "Stage", field: "currentStage", flex: 2.5, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.name == valueB?.name) return 0;
                                                        return (valueA?.name > valueB?.name) ? 1 : -1;
                                                    },
                                                    suppressMovable: true,
                                                    cellRenderer: (params) => <span>{params.value?.name}</span>
                                                },
                                                {
                                                    headerName: "Assigned To", field: "assignee", flex: 2, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.firstName == valueB?.firstName) return 0;
                                                        return (valueA?.firstName > valueB?.firstName) ? 1 : -1;
                                                    },
                                                    suppressMovable: true,
                                                    cellRenderer: (params) => {
                                                        if (params.value == null) return <span>Unassigned</span>;
                                                        return <span>{`${params.value?.firstName} ${params.value?.lastName}`}</span>
                                                    }
                                                },
                                                {
                                                    headerName: "Action", field: 'id', width: 180, cellClass: "grid-cell grid-cell-button",
                                                    cellRenderer: (params) => {
                                                        return (<Link to={ScreenUrls.Workflows.Edit(params.value)}>
                                                            Edit
                                                        </Link>)
                                                    },
                                                    suppressMovable: true,
                                                }
                                            ]}
                                            rowData={this.props.myWorkData.workflows}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Tasks assigned to me</h5>
                                    </div>

                                    <div className="row px-3">
                                        <div className="col-3">
                                            <SearchBox
                                                label="Client Name"
                                                htmlFor="clientName"
                                                value={this.state.clientName ?? ""}
                                                onBlurHandler={async (e) => {
                                                    this.updateHandler(e, "clientName")
                                                }}
                                            />

                                        </div>
                                        <div className="col-3">
                                            <SearchBox
                                                label="PO Number"
                                                htmlFor="PONumber"
                                                value={this.state.poNumber ?? ""}
                                                onBlurHandler={async (e) => {
                                                    this.updateHandler(e, "poNumber")
                                                }}
                                            />

                                        </div>
                                        <div className="col-3">
                                            <SearchBox
                                                label="Report Number"
                                                htmlFor="ReportNumber"
                                                value={this.state.reportNumber ?? ""}
                                                onBlurHandler={async (e) => {
                                                    this.updateHandler(e, "reportNumber")
                                                }}
                                            />

                                        </div>
                                    </div>

                                    <div className="card-block ag-theme-alpine large-grid">
                                        <AgGridReact
                                            pagination={true}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            defaultColDef={AgGridDefaultColDef}
                                            columnDefs={[
                                                {
                                                    suppressMovable: true,
                                                    headerName: "PO#",width: 100, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (nodeA?.data?.purchaseOrder?.poNumber == nodeB?.data?.purchaseOrder?.poNumber) return 0;
                                                        return (nodeA?.data?.purchaseOrder?.poNumber > nodeB?.data?.purchaseOrder?.poNumber) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params?.data?.purchaseOrder?.poNumber}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Report No", field: "reportNumber", width: 100, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.reportNumber == valueB?.reportNumber) return 0;
                                                        return (valueA?.reportNumber > valueB?.reportNumber) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params?.value ?? ""}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Client Name", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (nodeA?.data?.purchaseOrder?.clientName == nodeB?.data?.purchaseOrder?.clientName) return 0;
                                                        return (nodeA?.data?.purchaseOrder?.clientName > nodeB?.data?.purchaseOrder?.clientName) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.data?.purchaseOrder?.clientName}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Client Coordinator", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (nodeA?.data?.purchaseOrder?.clientCoordinatorName == nodeB?.data?.purchaseOrder?.clientCoordinatorName) return 0;
                                                        return (nodeA?.data?.purchaseOrder?.clientCoordinatorName > nodeB?.data?.purchaseOrder?.clientCoordinatorName) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.data?.purchaseOrder?.clientCoordinatorName}</span>
                                                },
                                               
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Client Coordinator Email", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (nodeA?.data?.purchaseOrder?.clientCoordinatorEmail == nodeB?.data?.purchaseOrder?.clientCoordinatorEmail) return 0;
                                                        return (nodeA?.data?.purchaseOrder?.clientCoordinatorEmail> nodeB?.data?.purchaseOrder?.clientCoordinatorEmail) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.data?.purchaseOrder?.clientCoordinatorEmail ?? "NA"}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Client Coordinator Number", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (nodeA?.data?.purchaseOrder?.clientCoordinatorPhoneNumber == nodeB?.data?.purchaseOrder?.clientCoordinatorPhoneNumber) return 0;
                                                        return (nodeA?.data?.purchaseOrder?.clientCoordinatorPhoneNumber > nodeB?.data?.purchaseOrder?.clientCoordinatorPhoneNumber) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.data?.purchaseOrder?.clientCoordinatorPhoneNumber?? "NA"}</span>
                                                },

                                                {
                                                    suppressMovable: true,
                                                    headerName: "Category", field: "category", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.name == valueB?.name) return 0;
                                                        return (valueA?.name > valueB?.name) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.value?.name}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Type", field: "purchaseOrderItem", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.type == valueB?.type) return 0;
                                                        return (valueA?.type > valueB?.type) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{PoType[params.value?.type]}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Description", field: "description", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Stage", field: "currentStage", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.name == valueB?.name) return 0;
                                                        return (valueA?.name > valueB?.name) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => <span>{params.value?.name}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Quantity", field: "quantity", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Priority", field: "priority", flex: 1, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => <span>{WorkflowTaskPriority[params.value]}</span>
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Action", field: "id", width: 100, cellClass: "grid-cell grid-cell-button",
                                                    cellRenderer: (params) => <Link to={ScreenUrls.Workflows.Task.Edit(params.value, params.data.workflowId)}>
                                                        Edit
                                                    </Link>
                                                }
                                            ]}
                                            rowData={this.props.myWorkData.tasks}>
                                        </AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state: IAppState) => ({
    myWorkData: state.myWork.myWorkData,
    dataLoading: state.myWork.dataLoading
})

export default connect(mapStateToProps, { loadMyWork })(MyWorkPage);
