import { PureComponent, createRef } from "react";
import { IUserModel } from "../../features/user/model/user.model";
import { IAppState } from "../../store";
import { connect } from "react-redux";
import { loadComments, createComment, editComment, deleteComment, setCommentData, setCommentDescription } from "../stores/comment.slice";
import { ICommentState } from "../stores/comment.slice";
import { ICommentModel, IGetAllCommentRequestModel } from "../model/comment.model";
import { fullDisplayDateInMilitaryTime } from "../utils";
import GridDeleteButtonControl from "../control/component/grid/grid-delete-buton.control";

export interface IProps {
    hideActions?: boolean,
    currentUser: IUserModel;
    comment: ICommentState;
    loadComments: (req: IGetAllCommentRequestModel) => void;
    entityId: number,
    entityType: number,
    createComment: (data: ICommentModel) => void;
    editComment: (id: ICommentModel) => void;
    deleteComment: (id: number) => void;
    setCommentData: (data: ICommentModel) => void;
    setCommentDescription: (description: string) => void;
}

export interface IState {
    editingCommentId: number
}

class CommentComponent extends PureComponent<IProps, IState> {
    inputRef: any = createRef();
    constructor(props) {
        super(props);
        this.state = {
            editingCommentId: 0,
        }
    }

    async componentDidMount(): Promise<any> {
        await this.props.loadComments({
            entityId: this.props.entityId,
            entityType: this.props.entityType,
            page: this.props.comment.pagination.page,
            pageSize: this.props.comment.pagination.pageSize,
        });
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any) {
        // this.inputRef.current?.scrollIntoView();
        if (prevProps.comment.commentList?.length && !this.props.comment?.commentList?.length || this.inputRef.current?.value) {
            if (this.inputRef.current?.value) {
                this.inputRef.current.value = "";
            }
            setTimeout(async () => {
                await this.props.loadComments(
                    {
                        entityId: this.props.entityId,
                        entityType: this.props.entityType,
                        page: this.props.comment.pagination.page,
                        pageSize: this.props.comment.pagination.pageSize,
                    }
                )
            }, 1)
        }
    }

    sendCommentHandler = async () => {
        if (this.inputRef?.current?.value?.length) {
            await this.props.createComment({
                entityId: this.props.entityId,
                entityType: this.props.entityType,
                commentDescription: this.inputRef?.current.value?.trimStart(),
            })

        }
    }

    fetchComments = async () => {
        await this.props.loadComments({
            entityId: this.props.entityId,
            entityType: this.props.entityType,
            page: this.props.comment.pagination.page + 1,
            pageSize: this.props.comment.pagination.pageSize,
        });
    }

    render() {
        const { currentUser, comment } = this.props;
        return (<div className="card commentBox">
            {(() => { if (comment.listLoading) return <div className="loading--bar"><span></span></div> })()}
            <div className="card-header card-form-header">
                <div className="card-form-header-title">
                    Comments
                </div>
            </div>

            <div className="card-block">
                <div className="row">
                    <div className={`col-12`}>
                        {
                            !this.props.hideActions &&
                            <div className="input-box mb-3" >
                                <input type="text"
                                    placeholder="Enter comment...."
                                    className=""
                                    ref={this.inputRef}
                                />
                                <button onClick={this.sendCommentHandler} disabled={comment?.createEditLoading} className="py-1 mr-0 mb-0 send-btn btn btn-primary">Send</button>
                            </div>
                        }
                        <div className={'old-messages'}>

                            {
                                comment?.commentList?.length > 0 ?
                                    comment?.commentList?.map((item: any) => {
                                        const commentByLoggedInUser = currentUser?.id == item?.createdById;
                                        const isEditing = this.state.editingCommentId == item?.id;
                                        return (
                                            <div key={item?.id} className={`d-flex ${commentByLoggedInUser ? "justify-content-end" : ""}`}>
                                                <div className={`single-comment ${isEditing ? "active" : ""}`}>
                                                    <small className="d-flex justify-content-between mb-1">
                                                        <div>
                                                            {commentByLoggedInUser ? "You" : `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`}
                                                            <span>&nbsp;&nbsp;| &nbsp;&nbsp;{fullDisplayDateInMilitaryTime(item?.createdOnUtc)}</span>
                                                        </div>
                                                        {
                                                            commentByLoggedInUser && !this.props.hideActions &&
                                                            <div className="text-end">
                                                                {(() => {
                                                                    if (isEditing) {
                                                                        return (
                                                                            <>
                                                                                <span className="mr-2" onClick={async () => {
                                                                                    await this.props.editComment(this.props.comment.commentData);
                                                                                    this.setState((prev) => {
                                                                                        return { editingCommentId: 0 }
                                                                                    });
                                                                                }}>
                                                                                    <i className={`feather icon-save font-14`}></i>
                                                                                </span>
                                                                                <span className="mr-2" onClick={async () => {
                                                                                    this.setState((prev) => {
                                                                                        return { editingCommentId: 0 }
                                                                                    });
                                                                                }}>
                                                                                    <i className={`feather icon-x font-13`}></i>
                                                                                </span>
                                                                            </>
                                                                        )
                                                                    }

                                                                    return (<span className="mr-2" onClick={() => {
                                                                        this.setState(
                                                                            { editingCommentId: item.id }
                                                                        );
                                                                        this.props.setCommentData(item);
                                                                    }}>
                                                                        <i className={`feather icon-edit-2 font-13`}></i>
                                                                    </span>)

                                                                })()}


                                                                <GridDeleteButtonControl
                                                                    customClass="font-13"
                                                                    onDelete={async () => {
                                                                        await this.props.deleteComment(item.id)
                                                                    }}>
                                                                </GridDeleteButtonControl>
                                                            </div>
                                                        }
                                                    </small>
                                                    {/* --- comment data ---- */}

                                                    <textarea
                                                        readOnly={!isEditing}
                                                        className={`comment ${isEditing ? "active" : ""}`}
                                                        onBlur={(e) => this.props.setCommentDescription(e.target.value)}>
                                                        {isEditing ? this.props.comment.commentData.commentDescription : item?.commentDescription}
                                                    </textarea>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="text-center">No Data</div>
                            }

                            {
                                ((comment.pagination.page * comment.pagination.pageSize) < comment.pagination.total) &&
                                <div className="text-center">
                                    <button
                                        disabled={comment?.listLoading}
                                        onClick={this.fetchComments}
                                        className="py-1 mr-0 mb-0 send-btn btn btn-primary">
                                        Load More
                                    </button>

                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = (state: IAppState) => ({
    comment: state.comment,
    currentUser: state.userSession.currentUser,
});

export default connect(mapStateToProps, { loadComments, createComment, editComment, deleteComment, setCommentData, setCommentDescription })(CommentComponent);
