import {BaseComponent} from "./base.component";
import React from "react";

export interface IProps {
    name: string
}
class WipComponent extends BaseComponent<IProps, any> {
    render() {
        return <div className="main-body">
            <div className="page-wrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div style={{ marginTop: "100px", minHeight: "100vh", textAlign: "center", verticalAlign: "middle" }}>
                            <i className="feather icon-lock" style={{ fontSize: "50px", color: "#3f4d67", fontWeight: "bold" }}></i>
                            <h4 style={{ fontFamily: "'Fira Code', monospace", fontSize: "36px", color: "#3f4d67", fontWeight: "bold",  marginTop: "10px" }}>
                                Coming Soon
                            </h4>
                            <h4 style={{ fontFamily: "'Fira Code', monospace", fontSize: "28px", color: "#a9b7d0", fontWeight: "bold",  marginTop: "40px" }}>
                                {this.props.name != null && this.props.name != "" ? `The ${this.props.name}`: 'This' } page is under construction
                            </h4>
                            <h4 style={{ fontFamily: "'Fira Code', monospace", fontSize: "20px", color: "#a9b7d0", fontWeight: "bold",  marginTop: "5px" }}>
                                Please check back soon
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default WipComponent;
