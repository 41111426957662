import { IWorkflowModel } from "../model/workflow.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import WorkflowService from "../service/workflow.service";
import { workflowDefault } from "../model/defaults/workflow.default";
import { IStoreSaveModel } from "../../../common/model/store-save.model";

export interface IWorkflowState {
    workflowList: IWorkflowModel[],
    workflowData: IWorkflowModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean
    stageMoveLoading: boolean
}

const initialState: IWorkflowState = {
    workflowList: [],
    workflowData: workflowDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false
}

export const loadWorkflows = createAsyncThunk("workflow/loadAll", WorkflowService.LoadAll);
export const loadWorkflowById = createAsyncThunk("workflow/loadById", WorkflowService.GetById);
export const createWorkflow = createAsyncThunk("workflow/create", WorkflowService.Create);
export const editWorkflow = createAsyncThunk("workflow/edit", WorkflowService.Edit);
export const moveToNextStage = createAsyncThunk("workflow/stage/move/next", WorkflowService.NextStage);
export const moveToRejectStage = createAsyncThunk("workflow/stage/move/reject", WorkflowService.RejectStage);
export const completeWorkflow = createAsyncThunk("workflow/complete", WorkflowService.Complete);
export const watchWorkflow = createAsyncThunk("workflow/HandleWatching", WorkflowService.HandleWatchWorkflow)

const workflowSlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        clearWorkflowDataState: (state) => { state.workflowData = workflowDefault },
        clearWorkflowListState: (state) => { state.workflowList = [] },
        updateWorkflowDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.workflowData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadWorkflows.pending, (state, action) => {
            clearWorkflowListState();
            state.listLoading = true;
        });
        builder.addCase(loadWorkflows.rejected, (state, action) => {
            clearWorkflowListState();
            state.listLoading = false;
        });
        builder.addCase(loadWorkflows.fulfilled, (state, action) => {
            state.workflowList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadWorkflowById.pending, (state, action) => {
            clearWorkflowDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadWorkflowById.rejected, (state, action) => {
            clearWorkflowDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadWorkflowById.fulfilled, (state, action) => {
            state.workflowData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createWorkflow.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createWorkflow.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createWorkflow.fulfilled, (state, action) => {
            state.createEditLoading = false;
            state.workflowData = action.payload;
        });

        // Edit
        builder.addCase(editWorkflow.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editWorkflow.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editWorkflow.fulfilled, (state, action) => {
            state.workflowData = action.payload;
            state.createEditLoading = false;
        });

        // Stage - Next
        builder.addCase(moveToNextStage.pending, (state, action) => { state.stageMoveLoading = true; });
        builder.addCase(moveToNextStage.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowData = action.payload;
        });
        builder.addCase(moveToNextStage.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });

        // Stage - Reject
        builder.addCase(moveToRejectStage.pending, (state, action) => { state.stageMoveLoading = true; });
        builder.addCase(moveToRejectStage.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowData = action.payload;
        });
        builder.addCase(moveToRejectStage.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });

        // Stage - Complete
        builder.addCase(completeWorkflow.pending, (state, action) => { state.stageMoveLoading = true; });
        builder.addCase(completeWorkflow.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowData = action.payload;
        });
        builder.addCase(completeWorkflow.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });
        builder.addCase(watchWorkflow.pending, (state, action) => {
            state.stageMoveLoading = true;
        });
        builder.addCase(watchWorkflow.fulfilled, (state, action) => {
            state.stageMoveLoading = false;
            state.workflowData = action.payload;
        });
        builder.addCase(watchWorkflow.rejected, (state, action) => {
            state.stageMoveLoading = false;
        });
    }
});

const { actions, reducer } = workflowSlice;

export const { clearWorkflowDataState, clearWorkflowListState, updateWorkflowDataState } = actions;

export default reducer;
