import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../../model/workflow-task.model";
import WorkflowTaskHelper from "../../../../common/helper/workflow-task.helper";
import { ValidationScreens } from "../../../../common/enums";
import {
    editWorkflowTask,
    loadWorkflowTaskById,
    updateWorkflowTaskDataState,
    completeWorkflowTask,
} from "../../store/workflow-task.slice";
import DatePicker from "react-datepicker";
import { AppConstants } from "../../../../common/app-constants";
import { localToUtcDate, utcToLocalDate } from "../../../../common/utils";
import { loadDataLoaders } from "../../../data-logger/store/data-logger.slice";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { IPurchaseOrderModel } from "../../../purchase-order/model/purchase-order.model";
import { IWorkflowModel } from "../../../workflow/model/workflow.model";
import { loadPurchaseOrderById } from "../../../purchase-order/store/purchase-order.slice";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

interface IProps {
    workflowTaskData: IWorkflowTaskModel;
    loadWorkflowTaskById: (id: number) => void;
    editWorkflowTask: (data: IWorkflowTaskModel) => void,
    completeWorkflowTask: (payload: IWorkflowTaskModel) => void;
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowTaskDataState: (payload: IStoreSaveModel) => void;
    workflowDataLoading: boolean;
    workflowTaskCreateEditLoading: boolean;
    purchaseOrderData: IPurchaseOrderModel;
    workflowData: IWorkflowModel;
    purchaseDataLoading: boolean;
    loadPurchaseOrderById: (id: number) => void;
    isNavCollapsed: boolean,
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class ActivityPlaningWorkflowTaskComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        if (this.props.workflowData.purchaseOrderId > 0) {
            await this.props.loadPurchaseOrderById(this.props.workflowData.purchaseOrderId);
        }
        await this.setValidator(ValidationScreens.WorkflowTaskInvoicingStage);
    }

    render() {
        if (this.state.hideForm) return;
        return <React.Fragment>
            <article className="card mb-4">
                {(() => {
                    if (this.props.workflowDataLoading || this.props.purchaseDataLoading) {
                        return <div className="loading--bar"><span></span></div>
                    }
                })()}

                <div className="card-header no-bottom-pad">
                    <div className="card-form-header-title"> Please complete following checklist before proceeding to next stage </div>
                </div>
                <div className="card-body">

                    <div className="row">
                        <div className="col-6">
                            <div className={`form-group ${this.vResult("invoiceGenerationDate").className}`}>
                                <label htmlFor="invoiceGenerationDate">Date Of Invoice Generation<span className="requried-span">*</span></label>
                                <DatePicker key="invoiceGenerationDate" selected={utcToLocalDate(this.props.workflowTaskData.invoiceGenerationDate ?? "")}
                                    dateFormat={AppConstants.DatePickerWithTimeFormat}
                                    timeIntervals={AppConstants.DatePickerInterval}
                                    showTimeSelect={true}
                                    {
                                    ...WorkflowTaskHelper.getMinMaxForDatePicker(this.props.workflowTaskData?.reportDeliveryEndDate)
                                    }
                                    onChange={(d) => {
                                        this.ValidateField("invoiceGenerationDate", localToUtcDate(d), this.props.workflowTaskData.reportDeliveryEndDate);
                                        this.props.updateWorkflowTaskDataState({ name: "invoiceGenerationDate", value: localToUtcDate(d) })
                                    }} />
                                <ValidationMessageControl message={this.vResult("invoiceGenerationDate").message} />
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <input id="hasServiceReportGenerated" type="checkbox"
                                className="checkbox--input"
                                disabled={!WorkflowTaskHelper.CheckListEditable(this.props.workflowTaskData?.invoiceGenerationDate ?? "")}
                                checked={this.props.workflowTaskData.hasServiceReportGenerated}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("hasServiceReportGenerated", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "hasServiceReportGenerated",
                                            value: !this.props.workflowTaskData.hasServiceReportGenerated
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="hasServiceReportGenerated" className="checkbox--label">
                                Please check if the service report has been generated for this task.
                            </label>
                            <div className={`${this.vResult("hasServiceReportGenerated").className}`}>
                                <ValidationMessageControl message={this.vResult("hasServiceReportGenerated").message} />
                            </div>
                        </div>

                        <div className="col-12">
                            <input id="hasClientAcknowledgeFinalReport" type="checkbox"
                                className="checkbox--input"
                                disabled={!WorkflowTaskHelper.CheckListEditable(this.props.workflowTaskData?.invoiceGenerationDate ?? "")}
                                checked={this.props.workflowTaskData.hasClientAcknowledgeFinalReport}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("hasClientAcknowledgeFinalReport", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "hasClientAcknowledgeFinalReport",
                                            value: !this.props.workflowTaskData.hasClientAcknowledgeFinalReport
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="hasClientAcknowledgeFinalReport" className="checkbox--label">
                                Please check if the client has acknowledged the final report.
                            </label>
                            <div className={`${this.vResult("hasClientAcknowledgeFinalReport").className}`}>
                                <ValidationMessageControl message={this.vResult("hasClientAcknowledgeFinalReport").message} />
                            </div>
                        </div>

                        <div className="col-12">
                            <input id="hasInvoicePrepared" type="checkbox"
                                className="checkbox--input"
                                disabled={!WorkflowTaskHelper.CheckListEditable(this.props.workflowTaskData?.invoiceGenerationDate ?? "")}
                                checked={this.props.workflowTaskData.hasInvoicePrepared}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("hasInvoicePrepared", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "hasInvoicePrepared",
                                            value: !this.props.workflowTaskData.hasInvoicePrepared
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="hasInvoicePrepared" className="checkbox--label">
                                Please check if the invoice has been prepared for this task.
                            </label>
                            <div className={`${this.vResult("hasInvoicePrepared").className}`}>
                                <ValidationMessageControl message={this.vResult("hasInvoicePrepared").message} />
                            </div>
                        </div>

                        <div className="col-12">
                            <input id="hasInvoiceReviewed" type="checkbox"
                                className="checkbox--input"
                                disabled={!WorkflowTaskHelper.CheckListEditable(this.props.workflowTaskData?.invoiceGenerationDate ?? "")}
                                checked={this.props.workflowTaskData.hasInvoiceReviewed}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("hasInvoiceReviewed", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "hasInvoiceReviewed",
                                            value: !this.props.workflowTaskData.hasInvoiceReviewed
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="hasInvoiceReviewed" className="checkbox--label">
                                Please check if the invoice has been reviewed by the Sales TL.
                            </label>
                            <div className={`${this.vResult("hasInvoiceReviewed").className}`}>
                                <ValidationMessageControl message={this.vResult("hasInvoiceReviewed").message} />
                            </div>
                        </div>

                        <div className="col-12">
                            <input id="hasInvoiceBookedByClient" type="checkbox"
                                className="checkbox--input"
                                disabled={!WorkflowTaskHelper.CheckListEditable(this.props.workflowTaskData?.invoiceGenerationDate ?? "")}
                                checked={this.props.workflowTaskData.hasInvoiceBookedByClient}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("hasInvoiceBookedByClient", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "hasInvoiceBookedByClient",
                                            value: !this.props.workflowTaskData.hasInvoiceBookedByClient
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="hasInvoiceBookedByClient" className="checkbox--label">
                                Please confirm if the invoice has been booked by the client.
                            </label>
                            <div className={`${this.vResult("hasInvoiceBookedByClient").className}`}>
                                <ValidationMessageControl message={this.vResult("hasInvoiceBookedByClient").message} />
                            </div>
                        </div>
                    </div>

                </div>
            </article>

            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading
                            || this.props.workflowTaskCreateEditLoading
                        }
                        onClick={e => {
                            e.preventDefault();
                            const error = !this.ValidateForm(this.props.workflowTaskData, this.props.workflowTaskData);
                            this.reloadForm();
                            if (error) return;

                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });
                        }}>Proceed</button>

                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.workflowTaskData.isComplete || this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });
                        }}>Reject</button>

                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.workflowTaskData.isComplete || this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflowTask(this.props.workflowTaskData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>

            {(() => { if (this.props.stageMoveLoading || this.props.workflowTaskCreateEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowTaskData: state.workflowTask.workflowTaskData,
    stageMoveLoading: state.workflowTask.stageMoveLoading,
    workflowDataLoading: state.workflowTask.dataLoading,
    workflowTaskCreateEditLoading: state.workflowTask.createEditLoading,
    dataLoggerList: state.dataLogger.dataLoggerList,
    dataLoggerListLoading: state.dataLogger.listLoading,
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    workflowData: state.workflow.workflowData,
    purchaseDataLoading: state.purchaseOrder.dataLoading,
    isNavCollapsed: state.userSession.isNavCollapsed,
})
export default connect(mapStateToProps, {
    loadWorkflowTaskById,
    editWorkflowTask,
    updateWorkflowTaskDataState,
    loadDataLoaders,
    loadPurchaseOrderById,
    openStageAssignmentModal,
    completeWorkflowTask
})(ActivityPlaningWorkflowTaskComponent);
