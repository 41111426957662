import {matchPath, Route, Routes, useLocation} from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import UserPage from "./screen/user.page";
import UserCreateEditPage from "./screen/form/user.create-edit.page";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";
import RouteHelper from "../../common/helper/route-helper";
import {AccessLevel, FeatureNames} from "../../common/enums";
import UserPasswordChangePage from "./screen/form/user.password-change.page";

const UserRoutes = () => {
    const user = RouteHelper.GetFeatureAccessLevel(FeatureNames.User);
    const location = useLocation();
    return <Routes>
        <Route element={<AccountLayout />}>
            <Route path={ScreenUrls.AccessDenied()} element={<AccessDeniedPage />} />

            <Route path={ScreenUrls.Users.Index()}>
                <Route index element={user.accessLevel >= AccessLevel.View
                        ? <UserPage accessLevel={user.accessLevel} />
                        : <AccessDeniedPage />} />
                <Route path={ScreenUrls.CreateUri} element={user.accessLevel >= AccessLevel.Create
                        ? <UserCreateEditPage accessLevel={user.accessLevel} />
                        : <AccessDeniedPage />} />
                <Route path={ScreenUrls.EditUriParam}
                       element={user.accessLevel >= AccessLevel.Edit
                           ? <UserCreateEditPage accessLevel={user.accessLevel}
                                                 id={matchPath({ path: ScreenUrls.Users.Edit(":id") }, location.pathname)?.params["id"]} />
                           : <AccessDeniedPage />} />
                <Route path={ScreenUrls.Users.PasswordChangeUriParam}
                       element={<UserPasswordChangePage accessLevel={user.accessLevel}
                                                        id={matchPath({ path: ScreenUrls.Users.PasswordChange(":id") }, location.pathname)?.params["id"]} />} />
            </Route>

        </Route>
    </Routes>
}

export default UserRoutes;
