import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export const redirectHandler = (url: string) => {
  setTimeout(() => {
    history.push(url);
  }, 0);
};

export default class ScreenUrls {
  static RootUrl: string = "/";
  public static CreateUri = "create";
  public static EditUri = "edit";
  public static EditUriParam = `${this.EditUri}/:id`;
  public static ViewUri = "view";
  public static ViewUriParam = `${this.ViewUri}/:id`;

  static Home = () => "/";
  static Welcome = () => "/welcome";
  static Login = () => ScreenUrls.getFullUrl("login");
  static Logout = () => ScreenUrls.getFullUrl("logout");
  static AccessDenied = () => ScreenUrls.getFullUrl("access-denied");

  // ==== forgot password pages links =====
  static ForgotPassword = ()=> ScreenUrls.getFullUrl("forgot-password");
  static ResetPassword =()=> ScreenUrls.getFullUrl("reset");
  static ResetConfirm =()=> ScreenUrls.getFullUrl("reset/confirm");
   // ==== end =====

  static MyWork = class {
    private static indexPage = "my-work";
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
  };

  static Dashboard = class {
    private static indexPage = "dashboard";
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
    static Notifications = () => ScreenUrls.getFullUrl(`${this.indexPage}/notifications`);
  };

  static Reports = class {
    private static indexPage = "report";
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
  };

  static Workflows = class {
    private static indexPage = "workflow";
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
    static View = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.ViewUri}/${id}`);
    static Edit = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.EditUri}/${id}`);
    static Task = class {
      public static indexPage = (workflowId) => `${workflowId}/tasks`;
      static View = (id, workflowId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.Workflows.indexPage}/${this.indexPage(workflowId)}`,
          `${ScreenUrls.ViewUri}/${id}`
        );
      static Create = (workflowId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.Workflows.indexPage}/${this.indexPage(workflowId)}`,
          ScreenUrls.CreateUri
        );
      static Edit = (id, workflowId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.Workflows.indexPage}/${this.indexPage(workflowId)}`,
          `${ScreenUrls.EditUri}/${id}`
        );
    };
    static ClientActivity = class {
      public static indexPage = (workflowId) => `${workflowId}/client-activity`;
      static Create = (workflowId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.Workflows.indexPage}/${this.indexPage(workflowId)}`,
          ScreenUrls.CreateUri
        );
      static Edit = (id, workflowId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.Workflows.indexPage}/${this.indexPage(workflowId)}`,
          `${ScreenUrls.EditUri}/${id}`
        );
    };
  };

  static DataLoggers = class {
    private static indexPage = "data-logger";

    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
    static Create = () =>
      ScreenUrls.getFullUrl(this.indexPage, ScreenUrls.CreateUri);
    static Edit = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.EditUri}/${id}`);
    static View = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.ViewUri}/${id}`);

    static Make = class {
      public static indexPage = "make";
      private static parentUri = `data-logger/${this.indexPage}`;
      static Index = () => ScreenUrls.getFullUrl(this.parentUri);
      static Create = () =>
        ScreenUrls.getFullUrl(this.parentUri, ScreenUrls.CreateUri);
      static Edit = (id) =>
        ScreenUrls.getFullUrl(this.parentUri, `${ScreenUrls.EditUri}/${id}`);
    };

    static Category = class {
      public static indexPage = "category";
      private static parentUri = `data-logger/${this.indexPage}`;
      static Index = () => ScreenUrls.getFullUrl(this.parentUri);
      static Create = () =>
        ScreenUrls.getFullUrl(this.parentUri, ScreenUrls.CreateUri);
      static Edit = (id) =>
        ScreenUrls.getFullUrl(this.parentUri, `${ScreenUrls.EditUri}/${id}`);
    };

    static Booking = class {
      public static indexPage = "booking";
      private static parentUri = `data-logger/${this.indexPage}`;
      static Index = () => ScreenUrls.getFullUrl(this.parentUri);
      static Create = (taskId) =>
        ScreenUrls.getFullUrl(
          this.parentUri,
          `${ScreenUrls.CreateUri}?taskId=${taskId}`
        );
      static Edit = (id, taskId) =>
        ScreenUrls.getFullUrl(
          this.parentUri,
          `${ScreenUrls.EditUri}/${id}?taskId=${taskId}`
        );
    };
  };

  static Clients = class {
    private static indexPage = "clients";
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
    static Create = () =>
      ScreenUrls.getFullUrl(this.indexPage, ScreenUrls.CreateUri);
    static Edit = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.EditUri}/${id}`);

    static Addresses = class {
      public static indexPage = (clientId) => `${clientId}/addresses`;
      static Create = (clientId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.Clients.indexPage}/${this.indexPage(clientId)}`,
          ScreenUrls.CreateUri
        );
      static Edit = (id, clientId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.Clients.indexPage}/${this.indexPage(clientId)}`,
          `${ScreenUrls.EditUri}/${id}`
        );
    };
  };

  static Users = class {
    private static indexPage = "users";
    private static passwordChange = "password-change";
    public static PasswordChangeUriParam = `:id/${this.passwordChange}`;
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
    static Create = () =>
      ScreenUrls.getFullUrl(this.indexPage, ScreenUrls.CreateUri);
    static Edit = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.EditUri}/${id}`);
    static PasswordChange = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${id}/${this.passwordChange}`);

    static AccessRole = class {
      public static indexPage = "access-role";
      private static parentUri = `users/${this.indexPage}`;
      static Index = () => ScreenUrls.getFullUrl(this.parentUri);
      static Create = () =>
        ScreenUrls.getFullUrl(this.parentUri, ScreenUrls.CreateUri);
      static Edit = (id) =>
        ScreenUrls.getFullUrl(this.parentUri, `${ScreenUrls.EditUri}/${id}`);
    };
  };

  static PurchaseOrder = class {
    private static indexPage = "purchase-order";
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
    static Create = () =>
      ScreenUrls.getFullUrl(this.indexPage, ScreenUrls.CreateUri);
    static Edit = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.EditUri}/${id}`);
    static View = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.ViewUri}/${id}`);

    static ServiceItem = class {
      public static indexPage = "service-item";
      private static parentUri = `purchase-order/${this.indexPage}`;
      static Index = () => ScreenUrls.getFullUrl(this.parentUri);
      static Create = () =>
        ScreenUrls.getFullUrl(this.parentUri, ScreenUrls.CreateUri);
      static Edit = (id) =>
        ScreenUrls.getFullUrl(this.parentUri, `${ScreenUrls.EditUri}/${id}`);
    };

    static LineItem = class {
      public static indexPage = (purchaseOrderId) =>
        `${purchaseOrderId}/line-item`;
      static Create = (purchaseOrderId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.PurchaseOrder.indexPage}/${this.indexPage(
            purchaseOrderId
          )}`,
          ScreenUrls.CreateUri
        );
      static Edit = (id, purchaseOrderId) =>
        ScreenUrls.getFullUrl(
          `${ScreenUrls.PurchaseOrder.indexPage}/${this.indexPage(
            purchaseOrderId
          )}`,
          `${ScreenUrls.EditUri}/${id}`
        );
    };
  };

  static Membership = class {
    private static indexPage = "membership";
    static Index = () => ScreenUrls.getFullUrl(this.indexPage);
    static Create = () =>
      ScreenUrls.getFullUrl(this.indexPage, ScreenUrls.CreateUri);
    static Edit = (id) =>
      ScreenUrls.getFullUrl(this.indexPage, `${ScreenUrls.EditUri}/${id}`);

    static Actions = class {
      public static changeUserProfile = "change-profile";
      public static changeUserProfileParam = `${this.changeUserProfile}/:id`;
      public static resetUserPassword = "change-profile";
      public static resetUserPasswordParam = `${this.resetUserPassword}/:id`;
      public static changeUserRole = "change-profile";
      public static changeUserRoleParam = `${this.changeUserRole}/:id`;
    };
  };

  static getFullUrl(parent: string, child?: string): string {
    return `${ScreenUrls.RootUrl}${parent}${child != null ? `/${child}` : ""}`;
  }
}
