import React from "react";
import { BaseComponent } from "./base.component";

export interface IProps {
    value: string,
    onBlurHandler: (e) => void,
    label?: string,
    htmlFor?: string,
    type?: string,
    placeholder?: string,
}

class InputComponent extends BaseComponent<IProps, {}> {
    private stepInput: any;
    constructor(props) {
        super(props);
        this.stepInput = React.createRef();
    }
    render() {
        return (
            <div className={`form-group`}>
                {
                    this.props.label &&
                    <label htmlFor={this.props.htmlFor}>{this.props.label}</label>
                }
                <div className={`${this.props?.value?.length > 0 ? "common-input-wrapper" : ""}`}>
                    <input
                        className="form-control"
                        ref={(ref) => this.stepInput.current = ref}
                        id={this.props?.htmlFor}
                        type={this.props.type ?? "text"}
                        placeholder={this.props.placeholder ?? ""}
                        onBlur={e => {
                            e.preventDefault();
                            if (this.stepInput.current) {
                                this.stepInput.current.value = e.target.value?.trimStart();
                            }
                            this.props.onBlurHandler({ ...e, target: { ...e.target, value: e.target.value?.trimStart() } });
                        }}
                    />
                    {
                        this.props?.value?.length > 0 &&
                        <div className="eye-div">
                            <span onClick={(e) => {
                                e.preventDefault();
                                if (this.stepInput.current) {
                                    this.stepInput.current.value = "";
                                }
                                this.props.onBlurHandler({ ...e, target: { ...e.target, value: "" } });
                            }}>
                                <i className={`feather icon-x`}></i>
                            </span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default InputComponent;