import {matchPath, Route, Routes, useLocation} from "react-router-dom";
import React from "react";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import ClientPage from "./screen/client.page";
import ClientCreateEditPage from "./screen/form/client.create-edit.page";
import ClientAddressCreateEditPage from "./screen/form/client-address.create-edit.page";
import RouteHelper from "../../common/helper/route-helper";
import {AccessLevel, FeatureNames} from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";

const ClientRoutes = () => {
    const client = RouteHelper.GetFeatureAccessLevel(FeatureNames.Client);
    const clientAddress = RouteHelper.GetFeatureAccessLevel(FeatureNames.ClientAddress);
    const location = useLocation();
    return <Routes>
        <Route element={<AccountLayout />}>

            <Route path={ScreenUrls.Clients.Index()}>
                <Route index element={client.accessLevel >= AccessLevel.View ? <ClientPage accessLevel={client.accessLevel} />: <AccessDeniedPage />} />
                <Route path={ScreenUrls.CreateUri}
                       element={client.accessLevel >= AccessLevel.Create
                           ? <ClientCreateEditPage accessLevel={client.accessLevel} accessLevelAddress={clientAddress.accessLevel} />
                           : <AccessDeniedPage /> } />
                <Route path={ScreenUrls.EditUriParam}
                       element={client.accessLevel >= AccessLevel.Edit
                            ? <ClientCreateEditPage id={matchPath({ path: ScreenUrls.Clients.Edit(":id") }, location.pathname)?.params["id"]}
                                                    accessLevel={client.accessLevel} accessLevelAddress={clientAddress.accessLevel} />
                            : <AccessDeniedPage /> } />

                <Route path={ScreenUrls.Clients.Addresses.indexPage(":clientId")}>
                    <Route path={ScreenUrls.CreateUri}
                           element={client.accessLevel >= AccessLevel.Create
                               ? <ClientAddressCreateEditPage
                                   clientId={matchPath({ path: ScreenUrls.Clients.Addresses.Create(":clientId") }, location.pathname)?.params["clientId"]}
                                   accessLevel={client.accessLevel} />
                               : <AccessDeniedPage /> } />
                    <Route path={ScreenUrls.EditUriParam}
                           element={client.accessLevel >= AccessLevel.Edit
                               ? <ClientAddressCreateEditPage
                                   id={matchPath({ path: ScreenUrls.Clients.Addresses.Edit(":id", ":clientId") }, location.pathname)?.params["id"]}
                                   clientId={matchPath({ path: ScreenUrls.Clients.Addresses.Edit(":id",":clientId") }, location.pathname)?.params["clientId"]}
                                   accessLevel={client.accessLevel} />
                               : <AccessDeniedPage /> } />
                </Route>
            </Route>

        </Route>
    </Routes>
}

export default ClientRoutes;
