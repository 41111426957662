import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../../model/workflow-task.model";
import {
    createWorkflowTask, editWorkflowTask,
    loadWorkflowTaskById,
    updateWorkflowTaskDataState,
    createWorkflowTaskWhenCategoryChanges,
    editWorkflowTaskWhenCategoryChanges,
} from "../../store/workflow-task.slice";
import { DeferredLoadOptions } from "../../../../common/utils";
import AsyncSelect from "react-select/async";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { IPurchaseOrderModel } from "../../../purchase-order/model/purchase-order.model";
import { IWorkflowModel } from "../../../workflow/model/workflow.model";
import { loadWorkflowById } from "../../../workflow/store/workflow.slice";
import { loadPurchaseOrderById } from "../../../purchase-order/store/purchase-order.slice";
import { IFormControlDropdownItemModel } from "../../../../common/control/model/form.control.field.model";
import PurchaseOrderLineItemService from "../../../purchase-order/service/purchase-order-line-item.service";
import CategoryService from "../../../../common/service/category.service";
import { PoType, ValidationScreens } from "../../../../common/enums";
import { IPurchaseOrderLineItemModel } from "../../../purchase-order/model/purchase-order-item.model";
import DropdownService from "../../../../common/service/dropdown.service";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ScreenUrls, { redirectHandler } from "../../../../common/screen-urls";
import { Navigate } from "react-router-dom";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import WorkflowTaskService from "../../service/workflow-task.service";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

interface IProps {
    workflowId: number,
    workflowTaskData: IWorkflowTaskModel,
    workflowData: IWorkflowModel,
    purchaseOrderData: IPurchaseOrderModel,
    loadWorkflowTaskById: (id: number) => void,
    loadWorkflowById: (id: number) => void,
    loadPurchaseOrderById: (id: number) => void,
    createWorkflowTask: (data: IWorkflowTaskModel) => void,
    editWorkflowTask: (data: IWorkflowTaskModel) => void,
    createWorkflowTaskWhenCategoryChanges: (data: IWorkflowTaskModel) => void,
    editWorkflowTaskWhenCategoryChanges: (data: IWorkflowTaskModel) => void,
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowTaskDataState: (payload: IStoreSaveModel) => void;
    purchaseOrderDataLoading: boolean;
    workflowDataLoading: boolean;
    workflowTaskCreateEditLoading: boolean;
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState {
    hideQualityDropdown: boolean,
    redirectUrl: string;
}

class CreationWorkflowTaskComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideQualityDropdown: false,
            hideForm: false,
            redirectUrl: ""
        }
    }

    get purchaseOrderId() {
        return this.props.workflowData.purchaseOrderId;
    }

    get isQuantityValid() {
        return this.props.workflowTaskData.purchaseOrderItem?.type != Number(PoType.Open)
            || (this.props.workflowTaskData.quantity != undefined
                && this.props.workflowTaskData.quantity > 0)
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.WorkflowTaskCreationStage);

        if (this.purchaseOrderId) {
            await this.props.loadPurchaseOrderById(this.purchaseOrderId);
        }

        await this.props.updateWorkflowTaskDataState({ name: "workflowId", value: this.props.workflowId });
        if (this.props.workflowTaskData?.id == 0 || this.props.workflowTaskData?.id == null) {
            this.props.updateWorkflowTaskDataState({ name: "assignee", value: this.props.workflowData?.assignee });
            this.props.updateWorkflowTaskDataState({ name: "assigneeId", value: this.props.workflowData.assigneeId })
        }
    }

    loadCategoryOption = (inputValue: string) => DeferredLoadOptions(inputValue, CategoryService.TaskCategoryDropdown);

    loadQuantityOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) => new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
        const remaining = await WorkflowTaskService.GetRemainingQuantity(this.props.workflowId, this.props.workflowTaskData.purchaseOrderItemId ?? 0);
        let list: IFormControlDropdownItemModel[] = [];
        for (let i = 1; i <= remaining; i++) {
            list.push({
                label: i.toString(),
                value: i.toString()
            });
        }

        if (search == null || search === "") resolve(list);
        resolve(list.filter(l => l.label.includes(search)))
    }));

    reloadQualityOption() {
        this.setState({ hideQualityDropdown: true });
        setTimeout(() => {
            this.setState({ hideQualityDropdown: false });
        }, 1);
    }

    loadPoLineItemOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) => new Promise<IFormControlDropdownItemModel[]>(resolve => {
        if (this.props.purchaseOrderData == null || this.props.purchaseOrderData.lineItems == null) {
            resolve([]); return;
        }

        let result: IPurchaseOrderLineItemModel[] = [];
        for (let i = 0; i < this.props.purchaseOrderData.lineItems.length; i++) {
            const l = this.props.purchaseOrderData.lineItems[i];
            if (l == null) continue;

            const at = this.props.workflowData.tasks?.filter(t => t.purchaseOrderItemId == l.id) ?? [];
            if (l.type == Number(PoType.Open) && at.length > 0 && (at[0].quantity ?? 0) >= l.quantity) { continue; }

            result.push(l);
        }

        const list = result.map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.PurchaseOrderLineItem(d),
                value: d.id.toString()
            }
            return obj;
        });

        if (search == null || search === "") resolve(list);
        resolve(list.filter(l => l.label.indexOf(search) > 0))
    }));

    render() {
        if (this.state.redirectUrl != "") {
            const url = this.state.redirectUrl;
            this.setState({ redirectUrl: "" });
            return <Navigate to={url} replace />
        }

        if (this.state.hideForm) return;
        return <React.Fragment>
            <article className="card mb-4">
                {(() => { if (this.props.workflowDataLoading || this.props.purchaseOrderDataLoading) return <div className="loading--bar"><span></span></div> })()}
                <div className="card-header no-bottom-pad">
                    <div className="card-form-header-title"> Please complete following checklist before proceeding to next stage </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className={`form-group ${this.vResult("purchaseOrderItemId").className}`}>
                                <label htmlFor="poLineItem">PO Line Item<span className="requried-span">*</span></label>
                                {(() => {
                                    if (this.props.purchaseOrderData == null || this.props.purchaseOrderData.lineItems == null || this.props.purchaseOrderData.lineItems.length == 0) return;
                                    return <AsyncSelect id="poLineItem" cacheOptions defaultOptions
                                        className="async-select-control"
                                        loadOptions={this.loadPoLineItemOption}
                                        value={this.props.workflowTaskData.purchaseOrderItem != null
                                            ? {
                                                label: DropdownDisplayHelper.PurchaseOrderLineItem(this.props.workflowTaskData.purchaseOrderItem),
                                                value: this.props.workflowTaskData.purchaseOrderItem.id.toString()
                                            }
                                            : { label: "Select Line Item", value: "" }}
                                        onChange={async (e) => {
                                            const poItemId = e?.value != "" && !isNaN(Number(e?.value)) ? Number(e?.value) : null;
                                            this.ValidateField("purchaseOrderItemId", poItemId);

                                            if (poItemId == null) return;

                                            const item = await PurchaseOrderLineItemService.GetById(poItemId);
                                            if (item == null) return;

                                            this.props.updateWorkflowTaskDataState({ name: "purchaseOrderItemId", value: item.id });
                                            this.props.updateWorkflowTaskDataState({ name: "purchaseOrderItem", value: item });
                                            this.props.updateWorkflowTaskDataState({ name: "quantity", value: null });

                                            this.reloadQualityOption();
                                        }} />
                                })()}
                                <ValidationMessageControl message={this.vResult("purchaseOrderItemId").message} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("categoryId").className}`}>
                                <label htmlFor="category">Category<span className="requried-span">*</span></label>
                                <AsyncSelect id="category" cacheOptions defaultOptions
                                    className="async-select-control"
                                    loadOptions={this.loadCategoryOption}
                                    value={this.props.workflowTaskData.category != null
                                        ? {
                                            label: DropdownDisplayHelper.Category(this.props.workflowTaskData.category),
                                            value: this.props.workflowTaskData.category.id.toString()
                                        }
                                        : { label: "Select Category", value: "" }}
                                    onChange={async (e) => {
                                        const cId = e?.value != "" ? Number(e?.value) : null;
                                        this.ValidateField("categoryId", cId);

                                        if (cId == null) return;

                                        const item = await CategoryService.GetById(cId);
                                        if (item == null) return;
                                        this.props.updateWorkflowTaskDataState({ name: "categoryId", value: item.id });
                                        this.props.updateWorkflowTaskDataState({ name: "category", value: item });
                                    }}
                                    isDisabled={!this.props.workflowTaskData?.purchaseOrderItemId}
                                />
                                <ValidationMessageControl message={this.vResult("categoryId").message} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className={`form-group ${this.vResult("priority").className}`}>
                                <label htmlFor="priority">Priority<span className="requried-span">*</span></label>
                                <AsyncSelect id="priority" cacheOptions defaultOptions
                                    className="async-select-control"
                                    loadOptions={DropdownService.WorkflowTaskPriority}
                                    value={this.props.workflowTaskData.priority ?? 0 > 0
                                        ? {
                                            label: DropdownDisplayHelper.TaskPriority(this.props.workflowTaskData.priority ?? 2),
                                            value: this.props.workflowTaskData.priority ? this.props.workflowTaskData.priority.toString() : 2
                                        }
                                        : { label: "Select Priority", value: "" }}
                                    onChange={async (e) => {
                                        const priority = e?.value != "" ? Number(e?.value) : null;
                                        this.ValidateField("priority", priority);

                                        if (priority == null) return;

                                        this.props.updateWorkflowTaskDataState({ name: "priority", value: priority });
                                    }} />
                                <ValidationMessageControl message={this.vResult("priority").message} />
                            </div>
                        </div>
                        {(() => {
                            if (this.props.workflowTaskData.purchaseOrderItem == null
                                || this.props.workflowTaskData.purchaseOrderItem?.type != Number(PoType.Open)) return;

                            return <div className="col-3">
                                <div className={`form-group ${!this.isQuantityValid ? 'error' : ''}`}>
                                    <label htmlFor="quantity">Quantity</label>
                                    {(() => {
                                        if (this.state.hideQualityDropdown) return;
                                        return <AsyncSelect id="quantity" cacheOptions defaultOptions
                                            className="async-select-control"
                                            isClearable={true}
                                            loadOptions={this.loadQuantityOption}
                                            maxMenuHeight={190}
                                            value={this.props.workflowTaskData.quantity != null
                                                ? {
                                                    label: this.props.workflowTaskData.quantity.toString(),
                                                    value: this.props.workflowTaskData.quantity.toString()
                                                }
                                                : { label: "Select Quantity", value: "" }}
                                            onChange={async (e) => {
                                                if (e?.value == undefined) {
                                                    this.props.updateWorkflowTaskDataState({ name: "quantity", value: null });
                                                    return;
                                                }

                                                const qty = e?.value != "" ? Number(e?.value) : null;
                                                if (qty == null) return;
                                                this.props.updateWorkflowTaskDataState({ name: "quantity", value: qty });
                                            }} />
                                    })()}
                                    {(() => {
                                        if (this.isQuantityValid) return;
                                        return <ValidationMessageControl message="Quality is required" />
                                    })()}
                                </div>
                            </div>
                        })()}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className={`form-group ${this.vResult("description").className}`}>
                                <label htmlFor="description">Description<span className="requried-span">*</span></label>
                                <textarea id="description" className="form-control" rows={4}
                                    value={this.props.workflowTaskData.description?.toString() ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("description", e.target.value);
                                        this.props.updateWorkflowTaskDataState({ name: "description", value: e.target.value });
                                    }} />
                                <ValidationMessageControl message={this.vResult("description").message} />
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <ButtonGroupComponent>
                <>
                    {this.props.workflowTaskData.id > 0 &&
                        <button type="button" className="btn btn--secondary btn--right"
                            disabled={this.props.stageMoveLoading
                                || this.props.workflowDataLoading
                                || this.props.workflowTaskData.id == 0
                                || this.hasError
                                || !(this.props.workflowTaskData.purchaseOrderItem?.type != Number(PoType.Open)
                                    || this.props.workflowTaskData.quantity != undefined)}
                            onClick={e => {
                                e.preventDefault();
                                const error = !this.ValidateForm(this.props.workflowTaskData);
                                this.reloadForm();
                                if (error) return;
                                // ---- open Stage assignment modal ----
                                this.props.openStageAssignmentModal({
                                    optionsList: this.props.workflowTaskData.nextStageAssigneeList,
                                    openModal: true,
                                    proceedBtnClicked: true,
                                });

                            }}>Proceed</button>
                    }
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading
                            || this.props.workflowDataLoading
                            || this.props.purchaseOrderDataLoading
                            || this.hasError}
                        onClick={async (e) => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowTaskData);
                            this.reloadForm();
                            if (error) return;

                            if (this.props.workflowTaskData.id == 0) {
                                await this.props.createWorkflowTask(this.props.workflowTaskData);
                                this.setState({ redirectUrl: ScreenUrls.Workflows.Task.Edit(this.props.workflowTaskData.id, this.props.workflowId) });
                            } else {
                                await this.props.editWorkflowTask(this.props.workflowTaskData);
                            }

                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>

            {(() => { if (this.props.stageMoveLoading || this.props.workflowTaskCreateEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}

        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowTaskData: state.workflowTask.workflowTaskData,
    workflowData: state.workflow.workflowData,
    workflowDataLoading: state.workflow.dataLoading,
    stageMoveLoading: state.workflowTask.stageMoveLoading,
    purchaseOrderData: state.purchaseOrder.purchaseOrderData,
    purchaseOrderDataLoading: state.purchaseOrder.dataLoading,
    workflowTaskCreateEditLoading: state.workflowTask.createEditLoading,
})
export default connect(mapStateToProps, {
    loadWorkflowTaskById,
    loadWorkflowById,
    loadPurchaseOrderById,
    createWorkflowTask,
    editWorkflowTask,
    updateWorkflowTaskDataState,
    openStageAssignmentModal,
    createWorkflowTaskWhenCategoryChanges,
    editWorkflowTaskWhenCategoryChanges,
})(CreationWorkflowTaskComponent);
