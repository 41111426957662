import React from "react";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IWorkflowTaskModel } from "../../model/workflow-task.model";
import DatePicker from "react-datepicker";
import { localToUtcDate, utcToLocalDate } from "../../../../common/utils";
import WorkflowTaskHelper from "../../../../common/helper/workflow-task.helper";
import { AppConstants } from "../../../../common/app-constants";
import {
    editWorkflowTask,
    loadWorkflowTaskById,
    updateWorkflowTaskDataState
} from "../../store/workflow-task.slice";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { ValidationScreens } from "../../../../common/enums";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import ButtonGroupComponent from "../../../../common/component/button-group.component";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

interface IProps {
    workflowTaskData: IWorkflowTaskModel;
    loadWorkflowTaskById: (id: number) => void;
    editWorkflowTask: (data: IWorkflowTaskModel) => void,
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowTaskDataState: (payload: IStoreSaveModel) => void;
    workflowDataLoading: boolean;
    workflowTaskCreateEditLoading: boolean;
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class ClientReviewWorkflowTaskComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.WorkflowTaskClientReviewStage);
    }

    render() {
        return <React.Fragment>
            <article className="card mb-4">
                {(() => { if (this.props.workflowDataLoading) return <div className="loading--bar"><span></span></div> })()}
                <div className="card-header no-bottom-pad">
                    <div className="card-form-header-title"> Please complete following checklist before proceeding to next stage </div>
                </div>
                <div className="card-body">
                    <div className="row mb-2">
                        <div className="col-12">
                            <input id="clientReviewRequired" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.clientReviewRequired}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("clientReviewRequired", (e));
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "clientReviewRequired",
                                            value: !this.props.workflowTaskData.clientReviewRequired
                                        });
                                    }, 1)
                                }} />

                            <label htmlFor="clientReviewRequired" className="checkbox--label">
                                Is client review required?
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className={`form-group ${this.vResult("clientConfirmationDate").className}`}>
                                <label htmlFor="clientConfirmationDate">Client Confirmation Date {this.props.workflowTaskData.clientReviewRequired && <span className="requried-span">*</span>}</label>
                                <DatePicker key="clientConfirmationDate" selected={utcToLocalDate(this.props.workflowTaskData.clientConfirmationDate ?? "")}
                                    dateFormat={AppConstants.DatePickerWithTimeFormat}
                                    timeIntervals={AppConstants.DatePickerInterval}
                                    showTimeSelect={true}
                                    {...WorkflowTaskHelper.getMinMaxForDatePicker(this.props.workflowTaskData.qaReviewEndDate)}
                                    onChange={(d) => {
                                        this.ValidateField("clientConfirmationDate", localToUtcDate(d), this.props.workflowTaskData?.qaReviewEndDate, this.props.workflowTaskData?.clientReviewRequired);
                                        this.props.updateWorkflowTaskDataState({ name: "clientConfirmationDate", value: localToUtcDate(d) })
                                    }} />
                                <ValidationMessageControl message={this.vResult("clientConfirmationDate").message} />
                            </div>
                        </div>
                        <div className="col-12">
                            <input id="isReportSharedToClient" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isReportSharedToClient}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isReportSharedToClient", (e), this.props.workflowTaskData?.qaReviewEndDate, this.props.workflowTaskData?.clientReviewRequired);
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isReportSharedToClient",
                                            value: !this.props.workflowTaskData.isReportSharedToClient
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isReportSharedToClient" className="checkbox--label">
                                Has the report been shared to client for review?
                            </label>
                            <div className={`${this.vResult("isReportSharedToClient").className}`}>
                                <ValidationMessageControl message={this.vResult("isReportSharedToClient").message} />
                            </div>
                        </div>
                        <div className="col-12">
                            <input id="isClientReviewCompleted" type="checkbox"
                                className="checkbox--input"
                                checked={this.props.workflowTaskData.isClientReviewCompleted}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.ValidateField("isClientReviewCompleted", (e), this.props.workflowTaskData?.qaReviewEndDate, this.props.workflowTaskData?.clientReviewRequired);
                                    setTimeout(() => {
                                        this.props.updateWorkflowTaskDataState({
                                            name: "isClientReviewCompleted",
                                            value: !this.props.workflowTaskData.isClientReviewCompleted
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isClientReviewCompleted" className="checkbox--label">
                                Has client review been completed for this workflow task?
                            </label>
                            <div className={`${this.vResult("isClientReviewCompleted").className}`}>
                                <ValidationMessageControl message={this.vResult("isClientReviewCompleted").message} />
                            </div>
                        </div>
                 
                    </div>
                </div>

            </article>
            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading
                            || this.props.workflowTaskCreateEditLoading
                        }
                        onClick={e => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowTaskData, this.props.workflowTaskData);
                            this.reloadForm();
                            if (error) return;

                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });
                        }}>Proceed</button>
                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowTaskData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });
                        }}>Reject</button>
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflowTask(this.props.workflowTaskData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>


            {(() => { if (this.props.stageMoveLoading || this.props.workflowTaskCreateEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowTaskData: state.workflowTask.workflowTaskData,
    stageMoveLoading: state.workflowTask.stageMoveLoading,
    workflowDataLoading: state.workflowTask.dataLoading,
    workflowTaskCreateEditLoading: state.workflowTask.createEditLoading,

})
export default connect(mapStateToProps, { openStageAssignmentModal, loadWorkflowTaskById, editWorkflowTask, updateWorkflowTaskDataState })(ClientReviewWorkflowTaskComponent);
