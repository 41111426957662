import { IWorkflowModel } from "../model/workflow.model";
import ApiUrls from "../../../common/api-urls";
import { IStageFlowItemModel } from "../model/_common.model";
import { IGetAllRequestModel } from "../../../common/model/get-all-request-model";
import HttpService from "../../../common/service/http.service";

export default class WorkflowService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IWorkflowModel[]> {
        return await HttpService.Get<IWorkflowModel[]>(ApiUrls.Workflow.GetAll());
    }
    static async Create(data: IWorkflowModel): Promise<IWorkflowModel> {
        return await HttpService.Post<IWorkflowModel, IWorkflowModel>(ApiUrls.Workflow.Create(), data);
    }

    static async Edit(data: IWorkflowModel): Promise<IWorkflowModel> {
        return await HttpService.Patch<IWorkflowModel, IWorkflowModel>(ApiUrls.Workflow.Edit(data.id), data);
    }

    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.Workflow.Delete(id));
    }

    static async GetById(id: number): Promise<IWorkflowModel> {
        return await HttpService.Get<IWorkflowModel>(ApiUrls.Workflow.GetById(id));
    }

    static async NextStage(data: IWorkflowModel): Promise<IWorkflowModel> {
        return await HttpService.Patch<IWorkflowModel, IWorkflowModel>(ApiUrls.Workflow.NextStage(data.id), data);
    }

    static async RejectStage(data: IWorkflowModel): Promise<IWorkflowModel> {
        return await HttpService.Patch<IWorkflowModel, IWorkflowModel>(ApiUrls.Workflow.RejectStage(data.id), data)
    }

    static async Complete(data: IWorkflowModel): Promise<IWorkflowModel> {
        return await HttpService.Patch<IWorkflowModel, IWorkflowModel>(ApiUrls.Workflow.Complete(data.id), data);
    }

    static async GetDefaultStage(): Promise<IStageFlowItemModel[]> {
        return await HttpService.Get<IStageFlowItemModel[]>(ApiUrls.Workflow.GetDefaultStageFlow());
    }

    // ============ watching ========
    static async HandleWatchWorkflow(query:{id: number, watching: boolean}): Promise<IWorkflowModel> {
        return await HttpService.Patch<{},IWorkflowModel>(ApiUrls.Workflow.Watch(query.id, query.watching),{});
    }
}
