import {IClientAddressModel, IClientModel} from "../../features/client/model/client.model";
import {
    IPurchaseOrderLineItemModel,
    IPurchaseOrderServiceItemModel
} from "../../features/purchase-order/model/purchase-order-item.model";
import {IUserModel} from "../../features/user/model/user.model";
import {PoType} from "../enums";
import {ICategoryModel} from "../model/category.model";
import {IAccessRoleModel} from "../../features/access/model/access-role.model";
import {IDataLoggerModel} from "../../features/data-logger/model/data-logger.model";
import {IPurchaseOrderModel} from "../../features/purchase-order/model/purchase-order.model";
import {IDataLoggerMakeModel} from "../../features/data-logger/model/data-logger-make.model";
import DropdownService from "../service/dropdown.service";
import {IDataLoggerBookingModel} from "../../features/data-logger/model/data-logger-booking.model";

export default class DropdownDisplayHelper {
    static Category(d: ICategoryModel): string {
        return `${d.name}`;
    }

    static Client(d: IClientModel): string {
        return `${d.companyName}`;
    }

    static ClientAddress(d: IClientAddressModel): string {
        return `[${d.displayName}] ${d.buildingName}, ${d.addressLine1}, ${d.addressLine2}, ${d.addressLine3}, ${d.landmark}, ${d.city}, ${d.state}`
    }

    static ServiceItem(d: IPurchaseOrderServiceItemModel): string {
        return `${d.code} - ${d.title} (${d.hsnSacCode})`;
    }

    static User(d: IUserModel | null): string {
        return d? `${d.firstName} ${d.lastName}`: '';
    }

    static AccessRole(d: IAccessRoleModel): string {
        return `${d.name}`;
    }

    static PurchaseOrder(d: IPurchaseOrderModel): string {
        return `${d.poNumber}`;
    }

    static PurchaseOrderLineItem(d: IPurchaseOrderLineItemModel): string {
        return `${PoType[d.type]} PO | ${d.description} - [${d.service?.code}] ${d.service?.title} | Qty - ${d.quantity}`;
    }

    static DataLogger(d: IDataLoggerModel): string {
        return `${d.name}`;
    }

    static DataLoggerMake(d: IDataLoggerMakeModel): string {
        return `${d.name}`;
    }

    static TaskPriority(d: number): string {
        const filtered = DropdownService.workflowTaskPriorityList
                            .filter(l => l.value == d.toString());
        return filtered != null && filtered.length > 0 ? filtered[0].label: "";
    }
}
