import { BaseComponent } from "../../component/base.component";
import { IFileModel } from "../../model/file.model";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import AsyncSelect from "react-select/async";
import DropdownService from "../../service/dropdown.service";
import { AgGridDefaultColDef, AppDefaults } from "../../app-defaults";
import { createFile } from "../../stores/file.slice";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { EntityType, FileStorageType } from "../../enums";
import FileService from "../../service/file.service";
import { fileDefault } from "../../model/defaults/common.defaults";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import ValidationMessageControl from "../validation-message.control";
import ReactModal from "react-modal";
import { ENV } from "../../environment";

const ModalSchema = Yup.object().shape({
    storageType: Yup.number().required("Required"),
    fileData: Yup.string().when("storageType", {
        is: FileStorageType.Upload,
        then: () => Yup.string().required('Select file')
    }),
    publicLink: Yup.string().when("storageType", {
        is: FileStorageType.Link,
        then: () => Yup.string().required("Public link is required"),
    }),
    name: Yup.string().when("storageType", {
        is: FileStorageType.Link,
        then: () => Yup.string().required("Name is required"),
        otherwise: () => Yup.string().notRequired(),
    }),
    description: Yup.string().notRequired(),
});

export interface IProps {
    title: string;
    entityType: number;
    entityId: number;
    editable?: boolean;
    gridClass?: string;
    hideEntityType?: boolean;
    createEditLoading: boolean;
    createFile: (data: IFileModel) => void;
}

class MultiFileControl extends BaseComponent<IProps, {
    fileData: IFileModel;
    fileList: IFileModel[];
    listLoading: boolean;
    listReloadRequired: boolean,
    openModal: boolean,
}> {
    constructor(props) {
        super(props);
        this.state = {
            fileData: {
                ...fileDefault
            },
            fileList: [],
            listLoading: false,
            listReloadRequired: false,
            openModal: false,
        }
    }

    async componentDidMount() {
        this.updateFileDataState({ entityType: this.props.entityType, entityId: this.props.entityId, storageType: AppDefaults.FileStorageType });
        await this.reloadFiles();
    }

    reloadFiles = async () => {
        setTimeout(async () => {
            this.updateState({ listLoading: true });
            const list = await FileService.LoadAll({ entityId: this.props.entityId, entityType: this.props.entityType });
            this.updateState({ fileList: list, listLoading: false, listReloadRequired: false });
        }, 200);
    }

    updateState(newState: any) {
        this.setState({
            ...this.state,
            ...newState
        });
    }

    updateFileDataState(data: any) {
        this.setState({
            ...this.state,
            fileData: {
                ...this.state.fileData,
                ...data
            }
        });
    }

    render() {
        return <React.Fragment>
            {(() => {
                {
                    (async () => {
                        if (!this.state.listLoading && this.state.listReloadRequired) {
                            await this.reloadFiles();
                        }
                    })()
                }

                const hideFileType = this.props.hideEntityType == null || this.props.hideEntityType === true;

                let colDef: ColDef[] = [];
                if (!this.props.editable) {
                    // delete button and edit button
                }

                colDef.push({
                    suppressMovable: true,
                    headerName: "Name", field: "name", flex: 1, sortable: true, cellClass: "grid-cell",
                    cellRenderer: (params) => <span>{params.value ?? ""}</span>
                });
                colDef.push({
                    suppressMovable: true,
                    headerName: "Description", field: "description", flex: 2, sortable: true, cellClass: "grid-cell",
                    cellRenderer: (params) => <span>{params.value ?? ""}</span>
                });
                colDef.push({
                    suppressMovable: true,
                    headerName: "", field: "publicLink", width: 150, sortable: true, cellClass: "grid-cell grid-cell-button",
                    cellRenderer: (params) => {
                        if (params.data.storageType == 0) return;

                        if (params.data.storageType === Number(FileStorageType.Upload)) {
                            return (<button onClick={async (e) => {
                                e.preventDefault();
                                const response = await FileService.Download(params.data.id);
                                if (response == null) return;

                                const a = document.createElement('a');
                                const blob = await response.blob();
                                a.href = window.URL.createObjectURL(blob);
                                a.download = params.data.name;
                                a.click();
                                a.remove();

                            }}>Download</button>)
                        }

                        if (params.data.storageType === Number(FileStorageType.Link)) {
                            if (params.value == null || params.value == "") return;
                            return (<button onClick={e => {
                                e.preventDefault();
                                window.open(params.value, '_blank');
                            }}>Open Link</button>)
                        }
                    }
                })
                colDef.push({
                    suppressMovable: true,
                    headerName: "", field: "id", width: 150, sortable: true, cellClass: "grid-cell grid-cell-button",
                    cellRenderer: (params) => {
                        return (<button disabled={!this.props.editable} onClick={async e => {
                            e.preventDefault();
                            await FileService.Delete(params.data.id);
                            await this.reloadFiles();
                        }} className="Delete">Delete</button>)
                    }
                })
                return <article className="card mb-4">
                    <div className="card-header card-form-header">
                        <div className="card-form-header-title">{this.props.title}</div>
                        {(() => {
                            if (this.props.editable == null || !this.props.editable) return;
                            return <button type="button" className="btn btn-primary card-form-header-button" onClick={() => this.setState({ ...this.state, openModal: !this.state.openModal })}>
                                Add File
                            </button>
                        })()}
                    </div>
                    <ReactModal
                        className="file-upload-modal"
                        isOpen={this.state.openModal}
                        onRequestClose={() => this.setState({ ...this.state, openModal: false })}
                    >
                        <Formik
                            initialValues={{
                                storageType: FileStorageType.Link,
                                fileData: "",
                                publicLink: "",
                                name: "",
                                description: "",
                            }}
                            validationSchema={ModalSchema}
                            onSubmit={(
                                values,
                                { setSubmitting }
                            ) => {

                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <header className="card-body d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">New File</h5>

                                        <button
                                            onClick={(e) => {
                                                window.open(ENV().sharePoint, "_blank")
                                            }}
                                            className="btn btn-primary m-0 font-12">
                                            Upload to SharePoint
                                        </button>
                                    </header>
                                    <div className="card-body">
                                        <div className="row">
                                            {(() => {
                                                if (hideFileType) return;
                                                return <div className="col-2">
                                                    <div className="form-group">
                                                        <label htmlFor="entityType">Entity Type</label>
                                                        <AsyncSelect id="entityType" cacheOptions defaultOptions
                                                            loadOptions={DropdownService.EntityType}
                                                            value={{
                                                                label: EntityType[this.state.fileData.entityType],
                                                                value: this.state.fileData.entityType.toString()
                                                            }}
                                                            onChange={async (e) => {
                                                                const entityType = Number(e?.value);
                                                                if (entityType == null || entityType == 0) return;

                                                                this.updateFileDataState({ entityType: entityType });
                                                            }} />
                                                    </div>
                                                </div>
                                            })()}
                                            <div className="col-2">

                                                <Field name="storageType">
                                                    {
                                                        ({ field, form }) =>
                                                            <div className={`form-group ${formik.errors?.storageType ? "error" : ""}`}>
                                                                <label htmlFor="fileType">Storage<span className="requried-span">*</span></label>
                                                                <AsyncSelect id="fileType" cacheOptions defaultOptions
                                                                    loadOptions={DropdownService.FileStorageType}
                                                                    value={{
                                                                        label: formik.values.storageType == 1 ? "Link" : "Upload",
                                                                        value: formik.values.storageType.toString(),
                                                                    }}
                                                                    onChange={async (e) => {
                                                                        const storage = Number(e?.value);
                                                                        formik.setFieldValue("storageType", storage ?? "");
                                                                        formik.validateField("storageType");
                                                                        if (storage == null || storage == 0) return;

                                                                        // this.updateFileDataState({ storageType: storage });
                                                                    }} />
                                                                {
                                                                    formik.errors?.storageType &&
                                                                    <ValidationMessageControl message={formik.errors?.storageType ?? ""} />
                                                                }

                                                            </div>
                                                    }
                                                </Field>
                                            </div>
                                            <div className={hideFileType ? 'col-10' : 'col-2'}>
                                                {(() => {
                                                    if (formik.values.storageType === Number(FileStorageType.Upload)) {
                                                        return (
                                                            <Field name="fileData">
                                                                {
                                                                    ({ field, form }) =>
                                                                        <div className={`form-group ${formik.errors?.fileData ? "error" : ""}`}>
                                                                            <label htmlFor="uploadStorageType">Files<span className="requried-span">*</span></label>
                                                                            <input id="uploadStorageType" type="file" multiple={false}
                                                                                className="form-control"
                                                                                onChange={(e) => {
                                                                                    e.preventDefault();
                                                                                    if (e.target.files != null && e.target.files.length > 0) {
                                                                                        const file = e.target.files[0];
                                                                                        formik.setFieldValue("fileData", file);
                                                                                        formik.validateField("fileData");
                                                                                        // this.updateFileDataState({ fileData: file, publicLink: "" });
                                                                                    }
                                                                                }}
                                                                                onClick={e => { e.stopPropagation(); }} />
                                                                            {
                                                                                formik.errors?.fileData &&
                                                                                <ValidationMessageControl message={formik.errors?.fileData ?? ""} />
                                                                            }
                                                                        </div>
                                                                }
                                                            </Field>)
                                                    }
                                                    else if (formik.values.storageType === Number(FileStorageType.Link)) {
                                                        return (
                                                            <Field name="publicLink">
                                                                {
                                                                    ({ field, form }) =>
                                                                        <div className={`form-group ${formik.errors?.publicLink ? "error" : ""}`}>
                                                                            <label htmlFor="linkStorageType">Link Url<span className="requried-span">*</span></label>
                                                                            <input id="linkStorageType" type="text"
                                                                                className="form-control"
                                                                                value={formik.values.publicLink}
                                                                                onChange={(e) => {
                                                                                    e.preventDefault();
                                                                                    formik.setFieldValue("publicLink", e.target.value ?? "");
                                                                                    formik.validateField("publicLink");
                                                                                    // this.updateFileDataState({ fileData: null, publicLink: e.target.value });
                                                                                }} />
                                                                            {
                                                                                formik.errors?.publicLink &&
                                                                                <ValidationMessageControl message={formik.errors?.publicLink ?? ""} />
                                                                            }
                                                                        </div>
                                                                }
                                                            </Field>)

                                                    }
                                                    return null;
                                                })()}
                                            </div>
                                        </div>
                                        {(() => {
                                            if (formik.values.storageType == Number(FileStorageType.Link)) {
                                                return <div className="row">
                                                    <div className="col-12">
                                                        <Field name="storageType">
                                                            {
                                                                ({ field, form }) =>
                                                                    <div className={`form-group ${formik.errors?.name ? "error" : ""}`}>
                                                                        <label htmlFor="name">Name<span className="requried-span">*</span></label>
                                                                        <input id="name" type="text" className="form-control"
                                                                            value={formik.values.name?.toString() ?? ""}
                                                                            onChange={e => {
                                                                                e.preventDefault();
                                                                                formik.setFieldValue("name", e.target.value ?? "");
                                                                                formik.validateField("name");
                                                                                // this.updateFileDataState({ name: e.target.value });
                                                                            }} />
                                                                        {
                                                                            formik.errors?.name &&
                                                                            <ValidationMessageControl message={formik.errors?.name ?? ""} />
                                                                        }
                                                                    </div>
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            }
                                        })()}
                                        <div className="row">
                                            <div className="col-12">
                                                <Field name="description">
                                                    {
                                                        ({ field, form }) =>
                                                            <div className={`form-group ${formik.errors?.description ? "error" : ""}`}>
                                                                <label htmlFor="fileDescription">Description</label>
                                                                <textarea id="fileDescription" className="form-control" rows={4}
                                                                    value={formik.values.description ?? ""}
                                                                    onChange={e => {
                                                                        e.preventDefault();
                                                                        formik.setFieldValue("description", e.target.value ?? "");
                                                                        formik.validateField("description");
                                                                        // this.updateFileDataState({ description: e.target.value });
                                                                    }} />
                                                            </div>
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <footer className="card-body text-right">

                                        <button className="btn btn-secondary mb-0"
                                            onClick={() => this.setState({ ...this.state, openModal: false })}
                                        >Close</button>
                                        <button className="btn btn-primary mb-0 mr-0"
                                            onClick={async () => {
                                                const result = await formik.validateForm();

                                                if (Object.keys(result)?.length > 0) {
                                                    return false;
                                                }
                                                const data: IFileModel = {
                                                    ...this.state.fileData,
                                                    storageType: formik.values.storageType,
                                                    fileData: formik.values.fileData ?? null,
                                                    publicLink: formik.values.publicLink ?? null,
                                                    name: formik.values.name ?? null,
                                                    description: formik.values.description ?? null,
                                                    entityType: this.props.entityType,
                                                    entityId: this.props.entityId
                                                }
                                                await this.props.createFile(data);
                                                this.updateState({ listReloadRequired: true, fileData: fileDefault });
                                                await this.reloadFiles();
                                                this.setState({ ...this.state, openModal: false })
                                            }}
                                        >Submit</button>

                                    </footer>
                                </Form>)}
                        </Formik>

                    </ReactModal>

                    <div className={`card-block ag-theme-alpine ${this.props.gridClass ?? 'mini-grid'}`}>
                        <AgGridReact
                            unSortIcon={true}
                            rowHeight={60}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowData={this.state.fileList}
                            defaultColDef={AgGridDefaultColDef}
                            columnDefs={colDef}>
                        </AgGridReact>
                    </div>
                </article>
            })()}
        </React.Fragment>;
    }
}

const mapStateToProps = (state: IAppState) => ({
    createEditLoading: state.file.createEditLoading
})

export default connect(mapStateToProps, { createFile })(MultiFileControl);
