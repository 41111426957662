import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPurchaseOrderModel } from "../model/purchase-order.model";
import PurchaseOrderService from "../service/purchase-order.service";
import { purchaseOrderDefault } from "../model/defaults/purchase-order.default";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import { IPurchaseOrderLineItemModel } from "../model/purchase-order-item.model";
import ScreenUrls from "../../../common/screen-urls";

export interface IPurchaseOrderState {
    purchaseOrderList: IPurchaseOrderModel[],
    purchaseOrderData: IPurchaseOrderModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
}

const initialState: IPurchaseOrderState = {
    purchaseOrderList: [],
    purchaseOrderData: purchaseOrderDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadPurchaseOrders = createAsyncThunk("purchase-order/loadAll", PurchaseOrderService.LoadAll);
export const loadPurchaseOrderById = createAsyncThunk("purchase-order/loadById", PurchaseOrderService.GetById);
export const createPurchaseOrder = createAsyncThunk("purchase-order/create", PurchaseOrderService.Create);
export const editPurchaseOrder = createAsyncThunk("purchase-order/edit", PurchaseOrderService.Edit);
export const watchPurchaseOrder = createAsyncThunk("workflow/HandleWatching", PurchaseOrderService.HandleWatchPurchaseOrder)

const purchaseOrderSlice = createSlice({
    name: 'purchase-order',
    initialState,
    reducers: {
        clearPurchaseOrderDataState: (state) => { state.purchaseOrderData = purchaseOrderDefault },
        clearPurchaseOrderListState: (state) => { state.purchaseOrderList = [] },
        updatePurchaseOrderDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.purchaseOrderData[action.payload.name] = action.payload.value;
        },
        addMergeLineItemToPurchaseOrderDataState: (state, action: PayloadAction<IPurchaseOrderLineItemModel>) => {
            let lineItems = state.purchaseOrderData.lineItems;
            if (lineItems == null) lineItems = [];

            if (action.payload.id > 0) {
                lineItems = lineItems.filter(a => a.id === action.payload.id);
            }

            lineItems.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadPurchaseOrders.pending, (state, action) => {
            clearPurchaseOrderListState();
            state.listLoading = true;
        });
        builder.addCase(loadPurchaseOrders.rejected, (state, action) => {
            clearPurchaseOrderListState();
            state.listLoading = false;
        });
        builder.addCase(loadPurchaseOrders.fulfilled, (state, action) => {
            state.purchaseOrderList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadPurchaseOrderById.pending, (state, action) => {
            clearPurchaseOrderDataState();
            state.dataLoading = true;
        });
        builder.addCase(loadPurchaseOrderById.rejected, (state, action) => {
            clearPurchaseOrderDataState();
            state.dataLoading = false;
        });
        builder.addCase(loadPurchaseOrderById.fulfilled, (state, action) => {
            state.purchaseOrderData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createPurchaseOrder.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createPurchaseOrder.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createPurchaseOrder.fulfilled, (state, action) => {
            state.createEditLoading = false;
            state.purchaseOrderData = action.payload;
            window.location.href = ScreenUrls.Workflows.Edit(action.payload.workflowId)
        });

        // Edit
        builder.addCase(editPurchaseOrder.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editPurchaseOrder.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editPurchaseOrder.fulfilled, (state, action) => {
            state.purchaseOrderData = action.payload;
            state.createEditLoading = false;
        });

        builder.addCase(watchPurchaseOrder.pending, (state, action) => {
            state.createEditLoading = true;
        });
        builder.addCase(watchPurchaseOrder.fulfilled, (state, action) => {
            state.createEditLoading = false;
            state.purchaseOrderData = action.payload;
        });
        builder.addCase(watchPurchaseOrder.rejected, (state, action) => {
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = purchaseOrderSlice;

export const { updatePurchaseOrderDataState, clearPurchaseOrderDataState, clearPurchaseOrderListState, addMergeLineItemToPurchaseOrderDataState } = actions;

export default reducer;
