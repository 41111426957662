import { IFormControlDropdownItemModel } from "./control/model/form.control.field.model";
import moment from "moment";
import { AppConstants } from "./app-constants";
import NotificationHelper from "./helper/notification.helper";

export const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (match, index) => args[index] || "");

export const DeferredLoadOptions = (
  inputValue: string,
  optionsFunc: (search: string) => Promise<IFormControlDropdownItemModel[]>
) => {
  return new Promise<IFormControlDropdownItemModel[]>((resolve) => {
    setTimeout(() => {
      if (optionsFunc == null) return;
      resolve(optionsFunc(inputValue));
    }, 1000);
  });
};

export const utcToLocalDate = (utcDate?: string) => {
  if (utcDate == null || utcDate == "" || utcDate?.includes("0001-01-01")) {
    return "";
  }

  if (
    moment(moment.utc(utcDate).toDate().getTime()).format("HH:mm:ss") ==
    "00:00:00"
  )
    return moment.utc(utcDate).toDate().setHours(8, 0);
  else return moment.utc(utcDate).toDate();
};

export const displayDate = (utcDate?: string) => {
  if (utcDate == null || utcDate == "" || utcDate?.includes("0001-01-01")) {
    return "-";
  }

  return moment(moment.utc(utcDate).toDate()).format(
    AppConstants.DisplayDateFormat
  );
};

export const fullDisplayDate = (utcDate?: string) => {
  if (utcDate == null || utcDate == "" || utcDate?.includes("0001-01-01")) {
    return "-";
  }

  return moment(moment.utc(utcDate).toDate()).format(
    AppConstants.FullDisplayDateFormat
  );
};

export const fullDisplayDateInMilitaryTime = (utcDate?: string) => {
  if (utcDate == null || utcDate == "" || utcDate?.includes("0001-01-01")) {
    return "-";
  }

  return moment(moment.utc(utcDate).toDate()).format(
    AppConstants.DisplayDateMilitaryFormat
  );
};

export const localToUtcDate = (localDate: string) => {
  if (localDate == null || localDate == "") {
    return "";
  }

  return moment.utc(moment(localDate)).format(AppConstants.UtcDateTimeFormat);
};

export const apiResponse = (data: any, fallback: any = null): any => {
  if (data != null && data.Error) {
    NotificationHelper.Error(data.Message);
    return fallback;
  }
  return data;
};
