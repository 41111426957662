import React from "react";
import store from "../../store";
import ValidatorHelper from "../helper/validator-helper";
import { IFieldValidationRequestModel, IFieldValidationResponseModel, IValidatorModel } from "../model/validator.model";
import { ValidationScreens } from "../enums";
import { setHasError, setScreenValidator, setValidationResults, updateValidationResult } from "../stores/validator.slice";
import { animateScroll as scroll } from 'react-scroll';

export interface IFormBaseState {
    hideForm: boolean
}

export class FormBaseComponent<props, state extends IFormBaseState> extends React.Component<props, state> {

    constructor(props: any) {
        super(props);
    }

    reloadForm = () => {
        this.setState({ hideForm: true });
        setTimeout(() => {
            this.setState({ hideForm: false });
        }, 1);
    }

    setHasError = (hasError: boolean) => {
        store.dispatch(setHasError(hasError));
    }

    async setValidator(screen: ValidationScreens) {
        await store.dispatch(setScreenValidator(screen));
    }

    get validatorData(): IValidatorModel {
        return store.getState().validator.validatorData;
    }

    get validationResults(): IFieldValidationResponseModel[] {
        return store.getState().validator.validationResults;
    }

    get hasError(): boolean {
        return store.getState().validator.hasError;
    }

    vResult(fieldName: string): IFieldValidationResponseModel {
        return ValidatorHelper.GetField(fieldName, this.validationResults);
    }

    ValidateField(fieldName: string, value: any, dependenceFieldValue?: any, validateIfFieldIsSet?: any): boolean {
        const payload: IFieldValidationRequestModel = { fieldName, value }
        const result = ValidatorHelper.ValidateField(this.validatorData, payload, dependenceFieldValue, validateIfFieldIsSet)
        store.dispatch(updateValidationResult(result));
        return !result.failed;
    }

    ValidateForm(formData: any, dependencies?: any): boolean {
        const results = ValidatorHelper.ValidateAll(this.validatorData, formData, dependencies);
        store.dispatch(setValidationResults(results));
        const failed = results.filter(v => v.failed);
        if (failed != null && failed.length > 0) {
            scroll.scrollToTop();
            return false;
        } else {
            return true;
        }
    }
}
