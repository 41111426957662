import React from "react";
import { IWorkflowModel } from "../../model/workflow.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { editWorkflow, updateWorkflowDataState } from "../../store/workflow.slice";
import { AgGridReact } from "ag-grid-react";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../../common/screen-urls";
import ValidationMessageControl from "../../../../common/control/validation-message.control";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import { PoType, ValidationScreens, WorkflowTaskPriority } from "../../../../common/enums";
import { AgGridDefaultColDef } from "../../../../common/app-defaults";
import { openStageAssignmentModal, IStageAssignment } from "../../../../common/stores/stage-assignment.slice";

import ButtonGroupComponent from "../../../../common/component/button-group.component";
interface IProps {
    workflowData: IWorkflowModel,
    editWorkflow: (data: IWorkflowModel) => void;
    onChange: () => void;
    stageMoveLoading: boolean;
    updateWorkflowDataState: (payload: IStoreSaveModel) => void;
    openStageAssignmentModal: (payload: IStageAssignment) => void,
}

export interface IState extends IFormBaseState { }

class TasksWorkflowComponent extends FormBaseComponent<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.WorkflowTasksStage);
    }

    render() {
        if (this.state.hideForm) return;

        return <React.Fragment>
            <article className={`card mb-4 ${this.vResult("tasks").className}`}>
                <div className="card-header card-form-header child-grid-title">
                    <div className="card-form-header-title">Tasks<span className="requried-span">*</span></div>
                    <Link className="btn btn-primary btn--right" to={ScreenUrls.Workflows.Task.Create(this.props.workflowData.id)}>
                        Add New
                    </Link>
                    <ValidationMessageControl message={this.vResult("tasks").message} />
                </div>

                <div className="card-block ag-theme-alpine mini-grid">
                    {(() => {
                        return <AgGridReact
                            unSortIcon={true}
                            rowHeight={60}
                            defaultColDef={AgGridDefaultColDef}
                            columnDefs={[
                                {
                                    suppressMovable: true,
                                    headerName: "#", field: "id", width: 100, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value == 0 ? ` - ` : params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Report No", field: "reportNumber", width: 100, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params?.value ?? "NA"}</span>
                                },

                                {
                                    suppressMovable: true,
                                    headerName: "Assignee", field: "assignee", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{DropdownDisplayHelper.User(params.value)}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Category", field: "category", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value?.name}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Type", field: "purchaseOrderItem", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{PoType[params.value?.type]}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Description", field: "description", flex: 3, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Stage", field: "currentStage", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value?.name}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Quantity", field: "quantity", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{params.value}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Priority", field: "priority", flex: 1, sortable: true, cellClass: "grid-cell",
                                    cellRenderer: (params) => <span>{WorkflowTaskPriority[params.value]}</span>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Action", field: "id", width: 130, cellClass: "grid-cell grid-cell-button",
                                    cellRenderer: (params) => <Link to={ScreenUrls.Workflows.Task.View(params.value, this.props.workflowData.id)}>
                                        View Task
                                    </Link>
                                },
                                {
                                    suppressMovable: true,
                                    headerName: "Status", field: "", width: 100, cellClass: "grid-cell grid-cell-button",
                                    cellRenderer: (params) => {
                                        return <span className={`pill ${params.data?.isComplete ? "inactive-pill" : "active-pill"}`}>{params.data?.isComplete ? "Closed" : "Open"}</span>
                                    }
                                }
                            ]}
                            pagination={true}
                            paginationAutoPageSize={true}
                            rowData={this.props.workflowData.tasks ?? []}>
                        </AgGridReact>
                    })()}
                </div>
            </article>
            <ButtonGroupComponent>
                <>
                    <button type="button" className="btn btn--secondary btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            const error = !this.ValidateForm(this.props.workflowData);
                            this.reloadForm();
                            if (error) return;
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.nextStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: true,
                            });

                        }}>Proceed</button>
                    <button type="button" className="btn btn--red btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();
                            // ---- open Stage assignment modal ----
                            this.props.openStageAssignmentModal({
                                optionsList: this.props.workflowData.rejectStageAssigneeList,
                                openModal: true,
                                proceedBtnClicked: false,
                            });

                        }}>Reject</button>
                    <button type="button" className="btn btn--primary-d2 btn--right"
                        disabled={this.props.stageMoveLoading}
                        onClick={e => {
                            e.preventDefault();

                            this.props.editWorkflow(this.props.workflowData);
                            this.props.onChange();
                        }}>Save</button>
                </>
            </ButtonGroupComponent>


            {(() => { if (this.props.stageMoveLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowData: state.workflow.workflowData,
    stageMoveLoading: state.workflow.stageMoveLoading,
})
export default connect(mapStateToProps, { openStageAssignmentModal, editWorkflow, updateWorkflowDataState })(TasksWorkflowComponent);
