export enum ColumnRenderType {
    Text,
    Switch,
    Link,
    Status,
    Menu,
    ButtonLink
}

export enum ColumnPropMappingType {
    None,
    Concat
}

export enum DataLoggerType {
    Wireless,
    Wired
}

export enum GenericFormGroupType {
    Normal = 1,
    DynamicRow
}

export enum GenericFormFieldType {
    None,
    ReadOnlyHeading,
    ReadOnlyText,
    Text,
    Dropdown,
    Datepicker
}

export enum EntityType {
    Others = 1,
    PurchaseOrder,
    Workflow,
    WorkflowTask,
    DataLogger,
    Booking
}

export enum FileStorageType {
    Link = 1,
    Upload
}

export enum WorkflowStage {
    None = 0,
    SalesDataEntry = 1,
    SalesReview,
    Tasks,
    Closure
}

export enum WorkflowTaskStage {
    None = 0,
    TaskCreation = 1,
    ActivityPlanning,
    Booking,
    SiteActivity,
    OperationsReview,
    ReportAssignment,
    ReportPreparation,
    ReportReview,
    QaAssignment,
    QaReview,
    ClientReview,
    ReportDelivery,
    Invoicing,
    TaskClosure,
}

export enum PoType {
    Defined = 1,
    Open
}

export enum UserStatus {
    Active = 1,
    InActive = 2
}

export enum WorkflowTaskPriority {
    Low = 1,
    Medium,
    High
}

export enum LocalStorageKeys {
    AccessToken = 1,
    TokenExpiry,
    "grid/tile view"
}

export enum FeatureNames {
    None = 0,
    Client = 1,
    ClientAddress,
    Dashboard,
    DataLogger,
    DataLoggerCategory,
    DataLoggerMake,
    PurchaseOrder,
    PurchaseOrderServiceItem,
    PurchaseOrderLineItem,
    AccessRole,
    User,
    Workflow,
    WorkflowTask,
    MyWork,
    Notifications,
}

export enum AccessLevel {
    None,
    View = 1,
    Edit,
    Create,
    Delete
}

export enum ValidationScreens {
    None,
    Login,
    AccessRole,
    Client,
    ClientAddress,
    Dashboard,
    DataLogger,
    DataLoggerCategory,
    DataLoggerMake,
    PurchaseOrderBasic,
    PurchaseOrderWithPOItem,
    PurchaseOrderServiceItem,
    PurchaseOrderLineItem,
    User,
    UserPasswordChange,
    WorkflowSalesDataEntryStage,
    WorkflowSalesReviewStage,
    WorkflowTasksStage,
    WorkflowClosureStage,
    WorkflowTaskCreationStage,
    WorkflowTaskBookingStage,
    WorkflowTaskSiteActivityStage,
    WorkflowTaskDocumentReviewStage,
    WorkflowTaskReportingStage,
    WorkflowTaskReportReviewStage,
    WorkflowTaskQualityAssuranceStage,
    WorkflowTaskClientReviewStage,
    WorkflowTaskReportDeliveryStage,
    WorkflowTaskActivityPlaningStage,
    WorkflowTaskInvoicingStage,
}

export enum ValidationType {
    None,
    Required,
    AlphabetOnly,
    NumberOnly,
    NumberGreaterThanZero,
    NumberMax,
    Regex,
    TasksCompleted,
    ListGreaterThanZero,
    RequiredIfTrue,
    DateShouldBeGreaterThenCurrentDate,
    DateShouldBeLessThenCurrentDate,
    DateShouldBeLessThenDependentDate,
    DateShouldBeGreaterDependentDate,
    IsEmail,
    IsMobileNo,
    IsPanCardNumber,
    IsOptional,
    IsValidGSTIN,
    ValidateIfFieldIsSet,
    ValueShouldBeSame,
    ValueShouldNotBeSame
}

export enum NotificationModalType {
    success = "success",
    error = "error",
    warning = "warning"
}

export enum Enviornments {
    local = "local",
    dev = "dev",
    staging = "staging",
    prod = "prod",
}
