    import React from "react";
    import {BrowserRouter} from "react-router-dom";
    import LoadingComponent from "./common/component/loading.component";
    import AnonymousRoutes from "./common/route/anonymous.routes";
    import WorkflowRoutes from "./features/workflow/workflow.routes";
    import DashboardRoutes from "./features/dashboard/dashboard.route";
    import ReportRoutes from "./features/report/report.routes";
    import PurchaseOrderRoutes from "./features/purchase-order/purchase-order.route";
    import ClientRoutes from "./features/client/client.routes";
    import DataLoggerRoutes from "./features/data-logger/data-logger.routes";
    import UserRoutes from "./features/user/user.routes";
    import WorkflowTaskRoutes from "./features/workflow-task/workflow-task-route";
    import AccessRoutes from "./features/access/access.routes";
    import WelcomeRoutes from "./features/_welcome/welcome.routes";
    import MyWorkRoutes from "./features/my-work/mywork.routes";
    import NotificationModal from './common/component/alert-modal.component';

    const AppRoutes = () => {
        return <>
                <BrowserRouter>
                    <React.Suspense fallback={<LoadingComponent />}>

                        <AnonymousRoutes />

                        <WelcomeRoutes />
                        <DashboardRoutes />
                        <MyWorkRoutes />
                        <ReportRoutes />
                        <PurchaseOrderRoutes />
                        <WorkflowRoutes />
                        <WorkflowTaskRoutes />
                        <DataLoggerRoutes />
                        <ClientRoutes />
                        <UserRoutes />
                        <AccessRoutes />

                    </React.Suspense>
                </BrowserRouter>
                {/* --- modal popup --- */}
                <NotificationModal />
                </>
    }

    export default AppRoutes;
