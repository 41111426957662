import { BaseComponent } from "../../../common/component/base.component";
import { IWorkflowTaskModel } from "../model/workflow-task.model";
import { Link, Navigate } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { AgGridReact } from "ag-grid-react";
import CommentComponent from "../../../common/component/comments.component";
import React from "react";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { clearWorkflowDataState } from "../../workflow/store/workflow.slice";
import { clearWorkflowTaskDataState, watchWorkflowTask, loadWorkflowTaskById } from "../store/workflow-task.slice";
import StageFlowStepperComponent from "../../workflow/component/stage-flow-stepper.component";
import MultiFileControl from "../../../common/control/file/multi-file.control";
import { fullDisplayDateInMilitaryTime } from "../../../common/utils";
import { AccessLevel, EntityType, WorkflowTaskPriority } from "../../../common/enums";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import { IUserModel } from "../../user/model/user.model";
import { AgGridDefaultColDef } from "../../../common/app-defaults";

export interface IProps {
    accessLevel: number;
    id?: string;
    workflowId?: string;
    workflowTaskData: IWorkflowTaskModel;
    dataLoading: false;
    loadWorkflowTaskById: (id: number) => void;
    clearWorkflowTaskDataState: () => void;
    clearWorkflowDataState: () => void;
    currentUser: IUserModel;
    watchWorkflowTask: (query: { id: number, watching: boolean }) => void,
}

class WorkflowTaskViewPage extends BaseComponent<IProps, any> {

    componentDidMount() {
        if (this.props.id != null && this.props.id != "" && Number(this.props.id) > 0) {
            this.props.loadWorkflowTaskById(Number(this.props.id));
        } else {
            this.props.clearWorkflowTaskDataState();
        }
    }

    componentWillUnmount(): void {
        this.props.clearWorkflowTaskDataState();
        this.props.clearWorkflowDataState();
    }

    render() {
        if (this.props.accessLevel < Number(AccessLevel.View) && !this.props.dataLoading && this.props.workflowTaskData.assigneeId) {
            if (this.props.workflowTaskData.assigneeId === this.props.currentUser.id) {
                // continue
            } else {
                return <Navigate to={ScreenUrls.AccessDenied()} replace />
            }
        }

        return <React.Fragment>
            {(() => { if (this.props.dataLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    {(() => {
                                        if (this.props.workflowTaskData.stageFlow == null || this.props.workflowTaskData.stageFlow.length == 0) return;
                                        return <StageFlowStepperComponent stageFlow={this.props.workflowTaskData.stageFlow}
                                            isCompleted={this.props.workflowTaskData.isComplete}
                                            customClass={"workflow-task-stages"} />
                                    })()}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <article className="card no-bg mb-4">
                                        <div className="card-header card-form-header">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>Assignee</label>
                                                        <p>{this.props.workflowTaskData.assignee == null
                                                            ? "Unassigned"
                                                            : `${this.props.workflowTaskData.assignee?.firstName} ${this.props.workflowTaskData.assignee?.lastName}`}</p>
                                                    </div>
                                                </div>
                                                <div className="col-3">

                                                </div>
                                                <div className="col-6 d-flex align-items-center justify-content-end">
                                                    {this.props.workflowTaskData?.id > 0 &&
                                                        <button className="border-0 bg-transparent d-flex align-items-center"
                                                            disabled={this.props.workflowTaskData?.isComplete}
                                                            onClick={async () => {
                                                                await this.props.watchWorkflowTask({ id: this.props.workflowTaskData.id, watching: !this.props.workflowTaskData?.isWatching });
                                                                this.setState({ ...this.state, hideForm: true });
                                                                setTimeout(() => {
                                                                    this.setState({ ...this.state, hideForm: false });
                                                                }, 1)
                                                            }}
                                                        >

                                                            <i
                                                                className={`feather mb-0 mr-2 h5 icon-eye${this.props.workflowTaskData.isWatching ? "-off" : ""
                                                                    }`}
                                                            ></i>
                                                            {this.props.workflowTaskData.isWatching ? "Un-watch" : "Watch"} Task
                                                        </button>
                                                    }

                                                    <Link to={ScreenUrls.Workflows.View(this.props.workflowId)}
                                                        className="btn m-0 mx-3 btn-primary btn--row-right">
                                                        Open Workflow
                                                    </Link>


                                                    <Link to={ScreenUrls.Workflows.Task.Edit(this.props.workflowTaskData.id, this.props.workflowId)}
                                                        className="btn btn-primary m-0 btn--row-right">
                                                        Edit Task
                                                    </Link>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>Priority</label>
                                                        <p>{this.props.workflowTaskData.priority ?? 2 > 0 ? WorkflowTaskPriority[this.props.workflowTaskData.priority ?? 2] : "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-group read-only">
                                                        <label>Category</label>
                                                        <p>{this.props.workflowTaskData.category ?
                                                            DropdownDisplayHelper.Category(this.props.workflowTaskData.category) : "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>

                                    {
                                        (() => {
                                            if (this.props.workflowTaskData.id < 1) return;
                                            return (
                                                <CommentComponent hideActions={true} entityType={EntityType.WorkflowTask} entityId={this.props.workflowTaskData?.id} />
                                            )
                                        })()
                                    }

                                    {/* Files */}
                                    {(() => {
                                        if (this.props.workflowTaskData != null && this.props.workflowTaskData.id > 0) {
                                            return <MultiFileControl title="Files"
                                                entityType={EntityType.WorkflowTask}
                                                entityId={this.props.workflowTaskData.id} editable={false} />
                                        }
                                    })()}


                                    {/* Stage Change History */}
                                    {(() => {
                                        if (this.props.workflowTaskData.stageChangeHistory == null) return;
                                        return <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">Audit Trail</div>
                                            </div>
                                            <div className="card-block ag-theme-alpine mini-grid">
                                                <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.workflowTaskData.stageChangeHistory}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Created On", field: "createdOn", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{fullDisplayDateInMilitaryTime(params.value)}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Stage Change", field: "stageChangedFrom", flex: 3, sortable: true, cellClass: "grid-cell",
                                                            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                if (valueA?.name == valueB?.name) return 0;
                                                                return (valueA?.name > valueB?.name) ? 1 : -1;
                                                            },
                                                            cellRenderer: (params) => <React.Fragment>
                                                                <span className="grid-cell-stage-from">{params.value?.name}</span>
                                                                <i className="feather icon-arrow-right grid-cell-stage-icon"></i>
                                                                <span className="grid-cell-stage-to">{params.data.stageChangedTo?.name}</span>
                                                            </React.Fragment>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Description", field: "description", flex: 4, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Created By", field: "createdBy", flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                if (valueA?.firstName == valueB?.firstName) return 0;
                                                                return (valueA?.firstName > valueB?.firstName) ? 1 : -1;
                                                            },
                                                            cellRenderer: (params) => <span>
                                                                {params.value == null ? "Unassigned" : `${params.value?.firstName} ${params.value?.lastName}`}
                                                            </span>
                                                        }
                                                    ]}>
                                                </AgGridReact>
                                            </div>
                                        </article>
                                    })()}


                                    {/* Workflow Change History */}
                                    {(() => {
                                        if (this.props.workflowTaskData.historyLog == null) return;
                                        return <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">Overall Change History</div>
                                            </div>
                                            <div className="card-block ag-theme-alpine" style={{ height: "350px" }}>
                                                <AgGridReact
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    pagination={true}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.props.workflowTaskData.historyLog}
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "DateTime", field: "datetime", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "Description", field: "description", flex: 1.5, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            suppressMovable: true,
                                                            headerName: "User", field: "user", flex: 2, sortable: true, cellClass: "grid-cell",
                                                            cellRenderer: (params) => <span>
                                                                {params.value == null ? "Unassigned" : `${params.value?.firstName} ${params.value?.lastName}`}
                                                            </span>
                                                        }
                                                    ]}>
                                                </AgGridReact>
                                            </div>
                                        </article>
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    workflowTaskData: state.workflowTask.workflowTaskData,
    dataLoading: state.workflowTask.dataLoading,
    currentUser: state.userSession.currentUser
})
export default connect(mapStateToProps, { clearWorkflowDataState, watchWorkflowTask, loadWorkflowTaskById, clearWorkflowTaskDataState })(WorkflowTaskViewPage);
