import {IPurchaseOrderModel} from "../model/purchase-order.model";
import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";
import {IGetAllRequestModel} from "../../../common/model/get-all-request-model";
import {IFormControlDropdownItemModel} from "../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";

export default class PurchaseOrderService {
    static async LoadAll(request?: IGetAllRequestModel): Promise<IPurchaseOrderModel[]> {
        return await HttpService.Get<IPurchaseOrderModel[]>(ApiUrls.PurchaseOrder.GetAll(request));
    }

    static async Create(data: IPurchaseOrderModel): Promise<IPurchaseOrderModel> {
        return await HttpService.Post<IPurchaseOrderModel, IPurchaseOrderModel>(ApiUrls.PurchaseOrder.Create(), data);
    }

    static async Edit(data: IPurchaseOrderModel): Promise<IPurchaseOrderModel> {
        return await HttpService.Patch<IPurchaseOrderModel, IPurchaseOrderModel>(ApiUrls.PurchaseOrder.Edit(data.id), data);
    }

    static async GetById(id: number): Promise<IPurchaseOrderModel> {
        return await HttpService.Get<IPurchaseOrderModel>(ApiUrls.PurchaseOrder.GetById(id));
    }

    static async PurchaseOrderDropdown(search: string): Promise<IFormControlDropdownItemModel[]> {
        let req: IGetAllRequestModel = { searchStr: search };
        return (await PurchaseOrderService.LoadAll(req)).map(d => {
            const obj: IFormControlDropdownItemModel = {
                label: DropdownDisplayHelper.PurchaseOrder(d),
                value: d.id.toString()
            }
            return obj;
        });
    }
    static async HandleWatchPurchaseOrder(query:{id: number, watching: boolean}): Promise<IPurchaseOrderModel> {
        return await HttpService.Patch<{},IPurchaseOrderModel>(ApiUrls.PurchaseOrder.Watch(query.id, query.watching),{});
    }
  
}
