import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {IMyWorkModel} from "../model/my-work.model";
import MyWorkService from "../service/my-work.service";
import {myWorkDefault} from "../model/defaults/my-work.default";

export interface IMyWorkState {
    myWorkData: IMyWorkModel,
    dataLoading: boolean
}

const initialState: IMyWorkState = {
    myWorkData: myWorkDefault,
    dataLoading: false
}

export const loadMyWork = createAsyncThunk("myWork/load", MyWorkService.Load);

const myWorkSlice = createSlice({
    name: 'myWork',
    initialState,
    reducers: {
        clearMyTaskListState: (state) => { state.myWorkData = myWorkDefault }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadMyWork.pending, (state, action) => {
            clearMyTaskListState();
            state.dataLoading = true;
        });
        builder.addCase(loadMyWork.rejected, (state, action) => {
            clearMyTaskListState();
            state.dataLoading = false;
        });
        builder.addCase(loadMyWork.fulfilled, (state, action) => {
            state.myWorkData = action.payload;
            state.dataLoading = false;
        });
    }
});

const { actions, reducer } = myWorkSlice;

export const { clearMyTaskListState } = actions;

export default reducer;
