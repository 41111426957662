import React from "react";
import { BaseComponent } from "../../../common/component/base.component";
import { IAppState } from "../../../store";
import { IDashboardSummaryModel } from "../model/dashboard.model";
import { connect } from "react-redux";
import { loadSummary } from "../store/dashboard.slice";
import DataWiseSummaryComponent from "../component/data-wise-summary.component";
import SummaryCardComponent from "../component/summary-card.component";
import { AgGridReact } from "ag-grid-react";
import { AgGridDefaultColDef } from "../../../common/app-defaults";

interface IProps {
    accessLevel: number,
    summaryData: IDashboardSummaryModel,
    summaryLoading: boolean,
    loadSummary: () => void,
}

class DashboardPage extends BaseComponent<IProps, any> {
    componentDidMount() {
        this.props.loadSummary();
    }

    render() {
        return <React.Fragment>
            <div className="pcoded-content dashboard">
                {/*{JSON.stringify(this.props.summaryData)}*/}
                {(() => { if (this.props.summaryLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">
                            {(() => {
                                if (this.props.summaryData == null || this.props.summaryData.dataLogger == null) return;
                                return <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card dashboard--section">
                                            <div className="card-header">
                                                <h5>Datalogger Summary</h5>
                                            </div>
                                            <div className="card-block">
                                                <div className="dashboard--summary-card-container">
                                                    <SummaryCardComponent title="Total"
                                                        count={this.props.summaryData.dataLogger.totalDataLoggerCount} />
                                                    <SummaryCardComponent title="Available" cardClass="green"
                                                        count={this.props.summaryData.dataLogger.availableDataLoggerCount} />
                                                    <SummaryCardComponent title="Damaged" cardClass="red"
                                                        count={this.props.summaryData.dataLogger.damagedDataLoggerCount} />
                                                    <SummaryCardComponent title="Booked" cardClass="dark"
                                                        count={this.props.summaryData.dataLogger.bookedDataLoggerCount} />
                                                    <SummaryCardComponent title="Due For Calibration" cardClass="red"
                                                        count={this.props.summaryData.dataLogger.dueForCalibration} />
                                                    <SummaryCardComponent title="Expired"
                                                        count={this.props.summaryData.dataLogger.calibrationDueExpired} />
                                                </div>
                                                <div className="dashboard-data-wise--container">
                                                    <p className="dashboard-data-wise--title">{"Make wise"}</p>
                                                    <div className="dashboard-data-wise--content ag-theme-alpine p-2">
                                                        <AgGridReact
                                                            domLayout='autoHeight'
                                                            pagination={true}
                                                            paginationAutoPageSize={true}
                                                            unSortIcon={true}
                                                            rowHeight={40}
                                                            defaultColDef={AgGridDefaultColDef}
                                                            columnDefs={[
                                                                {
                                                                    headerName: "Make", field: "displayName", flex: 2, sortable: true,
                                                                    suppressMovable: true,

                                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                                },

                                                                {
                                                                    headerName: "Total", field: "totalDataLoggerCount", flex: 1, sortable: true,
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                                },
                                                                {
                                                                    headerName: "Available", field: "availableDataLoggerCount", flex: 1, sortable: true,
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => <span className="">{params.value}</span>
                                                                },
                                                                {
                                                                    headerName: "Booked", field: "bookedDataLoggerCount", flex: 1, sortable: true,
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => {

                                                                        return <span>{params.value}</span>
                                                                    }
                                                                },
                                                                {
                                                                    headerName: "Damaged", field: "damagedDataLoggerCount", flex: 1, sortable: true,
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => {
                                                                        return <span >{params.value}</span>
                                                                    }
                                                                },
                                                                {
                                                                    headerName: "Due For Calibration", field: "dueForCalibration", flex: 1, sortable: true,
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => {
                                                                        return <span >{params.value}</span>
                                                                    }
                                                                },
                                                                {
                                                                    headerName: "Expired", field: "calibrationDueExpired", flex: 1, sortable: true,
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => {
                                                                        return <span >{params.value}</span>
                                                                    }
                                                                },

                                                            ]}
                                                            rowData={this.props.summaryData.dataLogger.makeWiseSummary}>
                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            })()}
                            {(() => {
                                if (this.props.summaryData == null || this.props.summaryData.workflow == null) return;
                                return <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card dashboard--section">
                                            <div className="card-header">
                                                <h5>Workflow Summary</h5>
                                            </div>
                                            <div className="card-block">
                                                <div className="dashboard--summary-card-container">
                                                    <SummaryCardComponent title="Total"
                                                        count={this.props.summaryData.workflow.totalWorkflowCount} />

                                                    <SummaryCardComponent title="Active"
                                                        count={this.props.summaryData.workflow.activeWorkflowCount} />

                                                    <SummaryCardComponent title="Completed" cardClass="green"
                                                        count={this.props.summaryData.workflow.completedWorkflowCount} />
                                                </div>

                                                <DataWiseSummaryComponent title="Stage wise"
                                                    data={this.props.summaryData.workflow.stageWiseSummary} />

                                                <DataWiseSummaryComponent title="Assignee wise"
                                                    data={this.props.summaryData.workflow.assigneeWiseSummary} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })()}

                            {/* ===== workflow task summary ===== */}

                            {(() => {
                                if (this.props.summaryData == null || this.props.summaryData.workflowTask == null) return;
                                return <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card dashboard--section">
                                            <div className="card-header">
                                                <h5>Workflow Task Summary</h5>
                                            </div>
                                            <div className="card-block">
                                                <div className="dashboard--summary-card-container">
                                                    <SummaryCardComponent title="Total"
                                                        count={this.props.summaryData.workflowTask.totalWorkflowCount} />

                                                    <SummaryCardComponent title="Active"
                                                        count={this.props.summaryData.workflowTask.activeWorkflowCount} />

                                                    <SummaryCardComponent title="Completed" cardClass="green"
                                                        count={this.props.summaryData.workflowTask.completedWorkflowCount} />
                                                </div>

                                                <DataWiseSummaryComponent title="Stage wise"
                                                    data={this.props.summaryData.workflowTask.stageWiseSummary} />

                                                <DataWiseSummaryComponent title="Assignee wise"
                                                    data={this.props.summaryData.workflowTask.assigneeWiseSummary} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({
    summaryData: state.dashboard.summaryData,
    summaryLoading: state.dashboard.summaryLoading
});

export default connect(mapStateToProps, { loadSummary })(DashboardPage);
