        import React from "react";
        import AppRoutes from "./routes";
        import {Provider} from "react-redux";
        import store, {persist} from "./store";
        import {ReactNotifications} from "react-notifications-component";
        import { PersistGate } from 'redux-persist/integration/react';
        //import {Events} from 'react-scroll'
        export interface IProps {}

        class App extends React.Component<IProps, any> {
            // componentDidMount(): void {
            //     Events.scrollEvent.register("begin", function() {
            //         console.log("begin", arguments);
            //       });
               
            //       Events.scrollEvent.register("end", function() {
            //         console.log("end", arguments);
            //       });
            // }
            render() {
                return <React.Fragment>
                    <ReactNotifications />
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persist}>
                            <AppRoutes />
                        </PersistGate>
                    </Provider>
                </React.Fragment>
            }
        }

        export default App;
