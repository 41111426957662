import React from "react";
import {BaseComponent} from "../../../common/component/base.component";
import WipComponent from "../../../common/component/wip.component";

export interface IProps {}

class ReportPage extends BaseComponent<IProps, any> {
    render() {
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    {/*<div className="page-header">
                        <div className="page-block">
                            <div className="page-header-title">
                                <h3 className="m-b-10">Reports</h3>
                            </div>
                        </div>
                    </div>*/}
                    <WipComponent name="report" />
                </div>
            </div>
        </React.Fragment>
    }
}

export default ReportPage;
