import { BaseComponent } from "../../../common/component/base.component";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import React from "react";
import { DeferredLoadOptions, displayDate } from "../../../common/utils";
import { IDataLoggerModel } from "../../data-logger/model/data-logger.model";
import { AgGridReact } from "ag-grid-react";
import { ICategoryModel } from "../../../common/model/category.model";
import { IPaginationModel } from "../../../common/model/pagination.model";
import DataLoggerMakeService from "../../data-logger/service/data-logger-make.service";
import AsyncSelect from "react-select/async";
import CategoryService from "../../../common/service/category.service";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import { IDataLoggerMakeModel } from "../../data-logger/model/data-logger-make.model";
import DataLoggerService from "../../data-logger/service/data-logger.service";
import { paginationDefaultState } from "../../../common/model/defaults/pagination.default";
import PaginationComponent from "../../../common/component/pagination.component";
import { AgGridDefaultColDef } from '../../../common/app-defaults';
import SearchBox from "../../../common/component/searchbox.component";

export interface IProps {
    defaultSelections?: IDataLoggerModel[] | null;
    onSelection: (data: IDataLoggerModel) => void;
    onUnSelection: (data: IDataLoggerModel) => void;
    onUnSelectionAll: () => void;
    category?: ICategoryModel | null;
    subCategory?: ICategoryModel | null;
}

class DataLoggerSelectorAccordionComponent extends BaseComponent<IProps, {
    collapsed: boolean,
    hideCategory: boolean,
    hideSubCategory: boolean,
    category?: ICategoryModel | null,
    subCategory?: ICategoryModel | null,
    serialNumber?: string,
    make?: IDataLoggerMakeModel | null,
    listLoading: boolean,
    dataLoggerList: IDataLoggerModel[],
    previousSelection?: IDataLoggerModel[] | null,
    pagination: IPaginationModel,
}> {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            hideCategory: false,
            hideSubCategory: false,
            category: props.category,
            subCategory: props.subCategory,
            serialNumber: "",
            listLoading: false,
            dataLoggerList: [],
            pagination: paginationDefaultState,
            previousSelection: this.props.defaultSelections
        }
    }

    async componentDidMount() {
        await this.reloadDataLoggers();
        this.reloadSubCategory();
    }

    reloadDataLoggers = async () => {
        setTimeout(async () => {
            const list = await DataLoggerService.LoadAll({
                categoryId: this.state.category?.id,
                subCategoryId: this.state.subCategory?.id,
                makeId: this.state.make?.id,
                serialNumber: this.state.serialNumber,
                page: this.state.pagination.page,
                pageSize: this.state.pagination.pageSize,
            });
            this.updateState({ dataLoggerList: list?.data, pagination: list?.pagination });

        }, 200)
    }

    loadCategoryOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        CategoryService.LoadCategoriesList({ searchStr: search, onlyParents: true }));
    loadSubCategoryOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        CategoryService.LoadCategoriesList({ searchStr: search, parentId: this.state.category?.id.toString() ?? "0" }));
    loadMakersOption = (inputValue: string) => DeferredLoadOptions(inputValue, DataLoggerMakeService.MakeDropdown);

    reloadCategory = () => {
        this.setState({ hideCategory: true });
        setTimeout(() => {
            this.setState({ hideCategory: false });
        }, 1);
    }

    reloadSubCategory = () => {
        this.setState({ hideSubCategory: true });
        setTimeout(() => {
            this.setState({ hideSubCategory: false });
        }, 1);
    }

    updateState(newState: any) {
        this.setState({
            ...this.state,
            ...newState
        });
    }

    render() {
        return <React.Fragment>
            {(() => { if (this.state.listLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
            <div className="accordion no-bg" id="dataLoggerSelectorAccordionContainer">
                <div className="card overflow-visible">
                    <div className="card-header">
                        <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                            data-target="#dataLoggerSelectorAccordionControl" aria-expanded="false" aria-controls="dataLoggerSelectorAccordionControl"
                            onClick={() => { this.setState({ collapsed: !this.state.collapsed }) }}>
                            <i className={`button-icon feather ${this.state.collapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                            DataLogger Selection
                        </button>
                    </div>
                    <div id="dataLoggerSelectorAccordionControl" className="collapse" aria-labelledby="headingOne"
                        data-parent="#dataLoggerSelectorAccordionContainer">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="parentCategoryId">Category</label>
                                        {(() => {
                                            if (this.state.hideCategory) return;
                                            return <AsyncSelect id="parentCategoryId" cacheOptions defaultOptions
                                                isClearable={true}
                                                loadOptions={this.loadCategoryOption}
                                                //isDisabled={this.state.category != null}
                                                value={this.state.category != null
                                                    ? { label: DropdownDisplayHelper.Category(this.state.category), value: this.state.category.id.toString() }
                                                    : { label: "Select Category", value: "" }}
                                                onChange={async (e) => {
                                                    if (e == null) {
                                                        this.updateState({ category: null, subCategory: null, pagination: { ...this.state.pagination, page: 1 } });

                                                        this.reloadSubCategory();
                                                        this.reloadCategory();
                                                        await this.reloadDataLoggers();

                                                        return;
                                                    }

                                                    const cId = Number(e?.value);
                                                    if (cId === null || cId === 0) return;

                                                    const category = await CategoryService.GetById(cId);
                                                    this.updateState({ category: category, pagination: { ...this.state.pagination, page: 1 } });
                                                    this.reloadSubCategory();
                                                    await this.reloadDataLoggers();
                                                }} />
                                        })()}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="subCategoryId">Sub Category</label>
                                        {(() => {
                                            if (this.state.hideSubCategory) return;
                                            return <AsyncSelect id="subCategoryId" cacheOptions defaultOptions
                                                isClearable={true}
                                                loadOptions={this.loadSubCategoryOption}
                                                isDisabled={this.state.category == null}
                                                value={this.state.subCategory != null
                                                    ? { label: DropdownDisplayHelper.Category(this.state.subCategory), value: this.state.subCategory.id.toString() }
                                                    : { label: "Select Sub Category", value: "" }}
                                                onChange={async (e) => {
                                                    if (e == null) {
                                                        this.updateState({ subCategory: null, pagination: { ...this.state.pagination, page: 1 } });
                                                        await this.reloadDataLoggers();
                                                        return;
                                                    }

                                                    const cId = Number(e?.value);
                                                    if (cId === null || cId === 0) return;

                                                    const subCategory = await CategoryService.GetById(cId);
                                                    this.updateState({ subCategory: subCategory, pagination: { ...this.state.pagination, page: 1 } });

                                                    await this.reloadDataLoggers();
                                                }} />
                                        })()}
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="dtGridMakeId">Make</label>
                                        <AsyncSelect id="dtGridMakeId" cacheOptions defaultOptions
                                            isClearable={true}
                                            loadOptions={this.loadMakersOption}
                                            value={this.state.make != null
                                                ? { label: DropdownDisplayHelper.DataLoggerMake(this.state.make), value: this.state.make.id.toString() }
                                                : { label: "Select Make", value: "" }}
                                            onChange={async (e) => {
                                                if (e == null) {
                                                    this.updateState({ make: null, pagination: { ...this.state.pagination, page: 1 } });
                                                    await this.reloadDataLoggers();
                                                    return;
                                                }

                                                const mId = Number(e?.value);
                                                if (mId === null || mId === 0) return;

                                                const make = await DataLoggerMakeService.GetById(mId);
                                                this.updateState({ make: make, pagination: { ...this.state.pagination, page: 1 } });

                                                await this.reloadDataLoggers();
                                            }} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <SearchBox
                                        label="Serial Number"
                                        htmlFor="dtGridSerialNumber"
                                        value={this.state.serialNumber ?? ""}
                                        onBlurHandler={async (e) => {
                                            if (e != null) {
                                                this.updateState({ serialNumber: e.target.value, pagination: { ...this.state.pagination, page: 1 } });
                                                await this.reloadDataLoggers();
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-block px-0 ag-theme-alpine">
                                        <AgGridReact
                                            domLayout='autoHeight'
                                            pagination={false}
                                            paginationAutoPageSize={true}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            rowSelection="multiple"
                                            columnDefs={[
                                                {
                                                    width: 45,
                                                    suppressMovable: true,
                                                    cellClass: "grid-cell",
                                                    checkboxSelection: (row) => {
                                                        if (row.data == null || row.data == undefined) return false;

                                                        // ===== check if data logger calibration due date passed =================
                                                        if (row.data?.isExpired) return false;
                                                        const existingSelection = this.state.previousSelection?.filter(d => row.data != null && d.id === row.data.id);

                                                        const isRowSelectable = row.node.isSelected() || (existingSelection != null && existingSelection.length > 0)
                                                            || (row.data != null && !row.data.isDamaged && row.data.isAvailable);

                                                        row.node.selectable = isRowSelectable;
                                                        return isRowSelectable;
                                                    },
                                                    cellRenderer: (params) => {
                                                        if (params.data.isDamaged) {
                                                            params.node.selectable = false;
                                                        }
                                                        if (this.props.defaultSelections != null) {
                                                            const filtered = this.props.defaultSelections.filter(d => d.id === params.data.id);
                                                            params.node.selected = filtered != null && filtered.length > 0;
                                                            return <span>{params.value}</span>
                                                        }

                                                        return <span>{params.value}</span>
                                                    },
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id', width: 80, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Name", field: "name", flex: 3, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Category", flex: 2, field: "category", sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.name == valueB?.name) return 0;
                                                        return (valueA?.name > valueB?.name) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value?.name}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Make", flex: 2, field: "make", sortable: true, cellClass: "grid-cell",
                                                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                        if (valueA?.name == valueB?.name) return 0;
                                                        return (valueA?.name > valueB?.name) ? 1 : -1;
                                                    },
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value?.name}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Serial #", flex: 3, field: "serialNumber", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Last Calibrate", flex: 2, field: "lastCalibratedOn", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{displayDate(params.value)}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Calibration Due", flex: 2, field: "calibrationDueOn", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{displayDate(params.value)}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Health", field: 'isDamaged', width: 120, sortable: true, cellClass: "grid-cell grid-cell-tag",
                                                    cellRenderer: (params) => {
                                                        return <label className={!params.value ? 'active' : 'inactive-red'}
                                                            style={{ borderRadius: "15px" }}>
                                                            {!params.value ? 'Healthy' : 'Damaged'}
                                                        </label>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Availability", field: 'isAvailable', width: 120, sortable: true, cellClass: "grid-cell grid-cell-tag",
                                                    cellRenderer: (params) => {
                                                        return <label
                                                            className={` ${params?.data?.isExpired ? 'inactive-red' : !params.value ? 'inactive' : 'active'} `} style={{ borderRadius: "15px" }}
                                                        >
                                                            {params?.data?.isExpired ? "Expired" : params.value ? 'Available' : 'Booked'}
                                                        </label>
                                                    }
                                                },
                                                {
                                               
                                                    headerName: "Action Buttons",
                                                    cellClass:
                                                        "grid-cell hide-column grid-cell-tag",
                                                    headerClass: "hide-column",
                                                    pinned: "right",
                                                    cellRenderer: (params) => {
                                                        return (
                                                            <div className="d-flex p-3 datalogger-tooltip">
                                                                <div className="w-50">
                                                                    <div className="mb-1">
                                                                        Name : {params.data?.name}
                                                                    </div>
                                                                    <div className="mb-1">
                                                                        Category :{" "}
                                                                        {params.data?.category?.name}
                                                                    </div>
                                                                    <div className="mb-1">
                                                                        Make :{" "}
                                                                        {params.data?.make?.name}
                                                                    </div>
                                                                    {params.data?.booking?.length >
                                                                        0 && (
                                                                            <div>
                                                                                Client :{" "}
                                                                                {
                                                                                    params.data?.booking[0]
                                                                                        ?.client?.companyName
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                <div className="w-50">
                                                                    <div className="mb-1">
                                                                        Serial :{" "}
                                                                        {params.data?.serialNumber}
                                                                    </div>
                                                                    <div className="mb-1">
                                                                        Last calibrate :{" "}
                                                                        {displayDate(
                                                                            params.data
                                                                                ?.lastCalibratedOn
                                                                        )}
                                                                    </div>
                                                                    <div className="mb-1">
                                                                        Calibration due :{" "}
                                                                        {displayDate(
                                                                            params.data
                                                                                ?.calibrationDueOn
                                                                        )}
                                                                    </div>
                                                                    {params.data?.booking?.length >
                                                                        0 && (
                                                                            <div>
                                                                                Booking modified on :
                                                                                {displayDate(
                                                                                    params.data?.booking[0]
                                                                                        ?.lastUpdatedOnUtc ??
                                                                                    params.data?.booking[0]
                                                                                        ?.createdOnUtc
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        );
                                                    },
                                                },
                                            ]}
                                            rowData={this.state.dataLoggerList}
                                            onRowSelected={(r) => {
                                                if (r.data == null) {
                                                    this.props.onUnSelectionAll();
                                                    return;
                                                }

                                                if (r.node.isSelected()) {
                                                    this.props.onSelection(r.data);
                                                } else {
                                                    this.props.onUnSelection(r.data);
                                                }
                                            }}
                                            suppressRowClickSelection={true}
                                            defaultColDef={AgGridDefaultColDef}>
                                        </AgGridReact>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12 position-static">
                                    <PaginationComponent
                                        itemsPerPage={this.state.pagination.pageSize}
                                        currentPage={this.state.pagination.page}
                                        totalRecords={this.state.pagination.total}
                                        onPageChangeHandler={(data) => {
                                            this.updateState({ pagination: { ...this.state.pagination, page: data.selected + 1 } });
                                            this.reloadDataLoggers();
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({})
export default connect(mapStateToProps, {})(DataLoggerSelectorAccordionComponent);

