import { BaseComponent } from "../../../common/component/base.component";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import SingleMail from "./notification.component";
import { quickView } from "../store/notification.slice";
import { IRecentView } from "../model/recent-view.model";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { AppConstants } from "../../../common/app-constants";
import { IUserModel } from "../../user/model/user.model";

export interface IRecentNotificationProps {
  quickView: () => void,
  recentNotifications: IRecentView,
  currentUser: IUserModel;
}

export interface IRecentNotificationState {
  toggle: boolean,
}

class RecentNotificationComponent extends BaseComponent<IRecentNotificationProps, IRecentNotificationState> {

  interval;
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
    };
  }

  async componentDidMount() {
    if (this.props.currentUser.id > 0) {
      await this.props.quickView();
    }
    this.interval = setInterval(async () => {
      if (this.props.currentUser?.id > 0) {
        await this.props.quickView();
      }

    }, 1000 * AppConstants.NotificationQuickViewSchedulerSec)
  }

  componentWillUnmount(){
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="dropdown drp-user notifications">
        <a
          href="src/views/compoments/layout#"
          className={`dropdown-toggle ${this.props.recentNotifications.totalUnreadCount > 0 ? "recent-new-msg-box" : ""}`}
          data-toggle="dropdown"
          data-count={this.props.recentNotifications.totalUnreadCount ?? "0"}
        >
          <i className="icon feather icon-bell"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right profile-notification notifications-div">
          <div className="pro-head d-flex f-14 justify-content-between align-items-center">
            Recent Notifications
            <div><Link to={ScreenUrls.Dashboard.Notifications()} className="text-light font-14">See all</Link></div>
          </div>
          <ul className="pro-body profile--body">
            {this.props.recentNotifications?.items?.length > 0 ?
              this.props.recentNotifications?.items.map((item, index) => {
                return (
                  <SingleMail key={index} item={item} />
                );
              })
              :
              <li className="mb-0 dropdown-item notification-card h3">No new notifications yet</li>
            }
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  recentNotifications: state.notification.recentNotifications,
  currentUser: state.userSession.currentUser,
})

export default connect(mapStateToProps, {
  quickView,
})(RecentNotificationComponent);
