import {Route, Routes} from "react-router-dom";
import React from "react";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import ReportPage from "./screen/report.page";
const ReportRoutes = () => {
    return <Routes>
        <Route element={<AccountLayout />}>

            <Route path={ScreenUrls.Reports.Index()}>
                <Route index element={<ReportPage />} />
            </Route>

        </Route>
    </Routes>
}

export default ReportRoutes;
